import BasePlatform from './base.js';

export default class PS5 extends BasePlatform {
  constructor() {
    super();
    console.info('Instantiated PS5...');
    try {
      window.msdk.device.disableSystemMedia();
      console.log('SystemMedia has been disabled');
      window.msdk.device.disableScreenshots();
      console.log('Screenshots have been disabled.');
      this.initialize();
    } catch (e) {
      console.log(`Failed to disable screenshots or disableSystemMedia: ${e}`);
    }
  }

  async initialize() {
    this.AVAttributes = await this.getDeviceAttributes();
    console.log('AVAttributes initialized:', this.AVAttributes);
  }

  async getDeviceAttributes() {
    try {
      const languages = [
        'ja',
        'en',
        'fr',
        'es',
        'de',
        'it',
        'nl',
        'pt',
        'ru',
        'ko',
        'zh_Hant',
        'zh-Hans',
        'fi',
        'sv',
        'da',
        'no',
        'pl',
        'pt_BR',
        'en_GB',
        'tr',
        'es_419',
        'ar',
        'fr_CA',
        'cs',
        'hu',
        'el',
        'ro',
        'th',
        'vi',
        'in'
      ];
      const displayInfo = await window.msdk.device.getDisplayInfoImmediate();
      const displaySystemName = await window.msdk.system.getServiceParameter(
        window.msdk.system.serviceParameter.id.SYSTEM_NAME
      );
      const displaySystemLang = await window.msdk.system.getServiceParameter(
        window.msdk.system.serviceParameter.id.LANG
      );
      this.deviceLanguage =
        displaySystemLang && displaySystemLang >= 0 && displaySystemLang < languages.length
          ? languages[displaySystemLang]
          : 'en-us';
      this.AVAttributes = {
        category: 'game_console',
        os_family: 'playstation',
        model: displaySystemName || null,
        os_version: displaySystemName || null,
        format: this.getFormat(displayInfo) || null,
        quality: displayInfo.dynamicRange,
        codec: ['AAC', 'AVC', 'HEVC', 'AC-3', 'E-AC-3'].toString() || null,
        locale: this.deviceLanguage || 'en'
      };
      return this.AVAttributes;
    } catch (error) {
      console.error('Error fetching device capabilities:', error);
    }
  }

  getFormat(info) {
    let format = '';
    if (info) {
      switch (info.resolution) {
        case 'SD':
          format = '480p';
          break;
        case 'HD':
          format = '720p';
          break;
        case 'FullHD':
        case 'FHD':
        case '2K':
          format = '1080p';
          break;
        case 'WQHD':
        case 'QHD':
          format = '2440p';
          break;
        case '4K':
        case 'UHD':
        case 'UltraHD':
          format = '2160p';
          break;
        case '8K':
          format = '4320p';
          break;
        default:
          format = '1080p';
          break;
      }
      format +=
        Math.trunc(info.refreshRate) >= '119'
          ? 120
          : Math.trunc(info.refreshRate) >= '59'
          ? '60'
          : '30';
      return format;
    }
    return '1080p30';
  }

  async getDeviceAudioVideoAttributes() {
    const AVAttributes = await this.getDeviceAttributes();
    return AVAttributes;
  }

  exit() {
    if (window && window.close) {
      console.info('window close');
      window.close();
    }
  }

  setVolume(value) {
    // this.uvpPlayer.volume(value);
    console.info(`setVolume to ${value} ignored`);
  }
}
