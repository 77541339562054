import Reflux from 'reflux';
import { hintsActions } from './hintsStore';
import { listPageActions } from './listPageStore';
import { videoTimeProxyStore } from './videoTimeProxyStore';
import { videoPlayerActions, videoPlayerStore } from './videoPlayerStore';
import { consumptionActions } from './consumptionStore';
import { configDataStore } from './configDataStore';
import { appStore } from './appStore';
import PlatformUtils from '../utils/platform';
import { ariaTTSServiceStore } from './ariaTTSServiceStore';
import timeFormatter from '../utils/time-formatter';
import isLive from '../utils/isLive';
import callWithTimer from '../utils/wait-timers';
import { analyticsVideoView } from './appkitAnalyticsStore';
import { manifestActions } from './manifestStore';
import { playlistStore, playlistActions } from './playlistStore';
import { videoAnalyticsStore as videoViewTrackingStore } from './videoViewTrackingStore';
import { recentlyWatchedStore } from './recentlyWatchedStore';
import { historyStore, historyActions } from './historyStore';
import conf from '../conf';
import _ from 'lodash';

export const playerControlsActions = Reflux.createActions([
  'focusOnPrimaryControls',
  'focusOnSecondaryControls',
  'focusOnPlaylist',
  'focusOnCaptionsButton',
  'playerControlsOnLeft',
  'playerControlsOnRight',
  'onClickFF',
  'onFFEnterUp',
  'onLongClickFF',
  'onLongClickREW',
  'onClickREW',
  'onPlayPauseClick',
  'resetPlayerTimeout',
  'seekBackDVR',
  'seekForwardDVR',
  'jumpToLiveDVR',
  'backToStartDVR',
  'dvr_focusBackToStart',
  'dvr_focusLive',
  'dvr_focusRewind',
  'dvr_focusFastForward',
  'dvr_focusPlayPauseButton',
  'calculateProgressDVR',
  'setBitmovinDvrOffset',
  'setBitmovinDvrStart'
]);

export const playerControlsStore = Reflux.createStore({
  listenables: playerControlsActions,

  state: {
    isPlayPauseButtonFocused: true,
    isPlaylistFocused: false,
    isCaptionsButtonFocused: false,
    progress: 0,
    playerCurrentTime: 0,
    playerDurationInMs: 0,
    playerIsPlaying: false,
    playlistIndex: null,
    isFFFocused: false,
    isRWFocused: false,
    countOfFFRWPress: 0,
    lastDirectionPressed: '',
    isFastForwarding: false,
    isRewinding: false,
    currentVideoSrc: null,
    currentDVROffset: 0,
    isEnterUpReceived: false,
    isLoading: false,
    bitmovinDvrStart: 0
  },
  init: function () {
    this.listenTo(videoTimeProxyStore, this.onPlayerTimeChange.bind(this));
    this.listenTo(manifestActions.setIsDVR, this.handleManifestStoreUpdate);
    this.listenTo(videoPlayerActions.setDurationDVR, this.calculateProgressDVR);
    this.FFRWPressTimeout = null;
  },
  getInitialState: function () {
    return this.state;
  },

  onPlayerTimeChange: function (playerState) {
    let trigger = false;

    if (this.state.playerIsPlaying !== playerState.data.isPlaying) {
      trigger = true;
    }

    this.state.playerIsPlaying = playerState.data.isPlaying;

    if (playerState.originator !== 'currentTime') {
      if (playlistStore.isCurrentVideoDVR()) {
        if (this.state.currentDVROffset === 0) {
          // Only increment duration on ticks when stream is at live point
          videoPlayerActions.setDurationDVR();
        }
        return;
      }
      this.state.playerDurationInMs = playerState.data.duration;

      if (!(this.state.isFastForwarding || this.state.isRewinding))
        this.calculateProgress(playerState.data.currentTime);

      trigger = true;
    }

    if (trigger) this.trigger(this.state);
  },

  handleManifestStoreUpdate: function () {
    console.log('is DVR, updating time');
    this.state.currentDVROffset = 0;
    this.calculateProgressDVR();
  },

  calculateProgress: function (currentTime) {
    if (playlistStore.isCurrentVideoDVR()) {
      return;
    }

    if (isNaN(currentTime)) {
      return;
    }

    const progress = ((currentTime * 1000) / this.state.playerDurationInMs) * 100;
    if (!isNaN(progress)) {
      this.state.progress = progress;
    }
    this.state.secondsLeftInVideo = (this.state.playerDurationInMs - currentTime * 1000) / 1000;
    this.state.playerCurrentTime = currentTime;
  },

  setBitmovinDvrStart: function (timestamp) {
    this.state.bitmovinDvrStart = timestamp;
    this.trigger(this.state);
  },

  calculateProgressDVR: function (progressToSet) {
    let newProgress;
    if (progressToSet === 0 || progressToSet === 100) {
      newProgress = progressToSet;
    } else {
      if (appStore.state.playerUsed === 'bitmovin') {
        const currentTime = videoPlayerStore.state.playerObjectTime * 1000;
        const end = Date.now();
        const start = this.state.bitmovinDvrStart;
        newProgress = ((currentTime - start) / (end - start)) * 100;
      } else {
        newProgress =
          100 -
          ((videoPlayerStore.state.duration - videoPlayerStore.state.playerObjectTime) /
            videoPlayerStore.state.duration) *
            100;
        if (PlatformUtils.isMagenta) {
          newProgress =
            (videoPlayerStore.state.playerObjectTime * 100) / videoPlayerStore.state.duration;
        }
      }
      if (playerControlsStore.state.currentDVROffset === 0 || newProgress > 100) newProgress = 100;
      if (newProgress < 0) newProgress = 0;
    }

    if (!isNaN(newProgress) && isFinite(newProgress)) {
      this.state.progress = newProgress;
    }
    this.trigger(this.state);
  },

  focusOnCaptionsButton: function (value) {
    this.state.isCaptionsButtonFocused = value !== 'undefined' ? value : false;
    this.trigger(this.state);
  },

  focusOnPrimaryControls: function () {
    this.resetPlayerTimeout();
    hintsActions.showUpHint();
    this.state.isPlayPauseButtonFocused = true;
    this.state.isPlaylistFocused = false;
    this.state.isCaptionsButtonFocused = false;
    this.trigger(this.state);
  },

  focusOnSecondaryControls: function () {
    this.resetPlayerTimeout();
    hintsActions.clearHints();
    this.state.isPlayPauseButtonFocused = false;
    this.state.isPlaylistFocused = false;
    this.state.isFFFocused = false;
    this.state.isRWFocused = false;
    this.state.isBackToStartFocused = false;
    this.state.isLiveFocused = false;

    this.state.isCaptionsButtonFocused = true;
    this.trigger(this.state);
  },

  focusOnPlaylist: function (value) {
    this.resetPlayerTimeout();
    hintsActions.clearHints();
    this.state.isPlayPauseButtonFocused = false;
    this.state.isCaptionsButtonFocused = false;
    this.state.isFFFocused = false;
    this.state.isRWFocused = false;
    this.state.isPlaylistFocused = value !== 'undefined' ? value : false;
    this.trigger(this.state);
  },

  playerControlsOnLeft: function () {
    this.resetPlayerTimeout();
    clearTimeout(this.playTimeout);
    if (this.state.isFFFocused) {
      this.state.isFFFocused = false;
      this.state.isPlayPauseButtonFocused = true;
      videoPlayerActions.setCurrentTime(this.state.playerCurrentTime);

      this.state.isFastForwarding === true
        ? (this.playTimeout = setTimeout(this.resumePlay, 1500))
        : (this.playTimeout = undefined);
    } else {
      this.state.isRWFocused = true;
      this.state.isPlayPauseButtonFocused = false;
    }
    this.trigger(this.state);
  },

  playerControlsOnRight: function () {
    this.resetPlayerTimeout();
    clearTimeout(this.playTimeout);
    if (this.state.isRWFocused) {
      this.state.isRWFocused = false;
      this.state.isPlayPauseButtonFocused = true;
      videoPlayerActions.setCurrentTime(this.state.playerCurrentTime);

      this.state.isRewinding === true
        ? (this.playTimeout = setTimeout(this.resumePlay, 1500))
        : (this.playTimeout = undefined);
    } else {
      this.state.isFFFocused = true;
      this.state.isPlayPauseButtonFocused = false;
    }
    this.trigger(this.state);
  },

  resumePlay: function () {
    this.state.isFFFocused = false;
    this.state.isRWFocused = false;
    this.state.isLiveFocused = false;
    this.state.isBackToStartFocused = false;
    this.state.isPlayPauseButtonFocused = true;
    this.state.isRewinding = false;
    this.state.isFastForwarding = false;
    this.state.isCaptionsButtonFocused = false;
    this.state.countOfFFRWPress = 0;
    if (!(appStore.state.playerUsed === 'bitmovin' && playlistStore.isCurrentVideoDVR())) {
      videoPlayerActions.jumpToTime(this.state.playerCurrentTime);
    }
    clearTimeout(this.playTimeout);
    this.playTimeout = 0;
    // samsung platforms and PS3 play after the jump completes
    if (PlatformUtils.isTizen && !playlistStore.isCurrentVideoDVR()) {
      // || PlatformUtils.isPS5) {
      // tizen fails to start indicating buffering on its own.
      videoPlayerActions.setIsBuffering(true);
    } else if (PlatformUtils.isPS3) {
      // eslint-disable-line
    } else {
      videoPlayerActions.playVideo();
      this.state.playerIsPlaying = true;
    }
    console.log('RESUME - this.state.playerCurrentTime=', this.state.playerCurrentTime);
    analyticsVideoView('playVideo', { absolutePosition: this.state.playerCurrentTime });
    this.state.isLoading = false;
    this.trigger(this.state);
  },

  onPlayPauseClick: function (force) {
    force = force === true;
    // dont allow a play pause click if the user is seeking
    if (this.playTimeout) return;

    this.resetPlayerTimeout();
    this.state.isFFFocused = false;
    this.state.isRWFocused = false;
    this.state.isRewinding = false;
    this.state.isFastForwarding = false;

    if (
      !videoPlayerStore.state.isPlaying &&
      Math.abs(this.state.playerCurrentTime - videoPlayerStore.state.currentTime) > 5
    ) {
      ariaTTSServiceStore.readText('playing video');
      // tizen plays after the jump completes
      if (!PlatformUtils.isTizen || playlistStore.isCurrentVideoDVR()) {
        videoPlayerActions.playVideo();
      } else {
        // console.log("PLAYER CNTRL"+" tizen -- letting the player handle playVideo");
      }
      if (playlistStore.isCurrentVideoDVR()) {
        clearInterval(this.dvrPausedInterval);
      }
    } else if (videoPlayerStore.state.isPlaying && !force) {
      ariaTTSServiceStore.readText('paused video', true);
      videoPlayerActions.pauseVideo();
      if (playlistStore.isCurrentVideoDVR()) {
        if (this.dvrPausedInterval) {
          clearInterval(this.dvrPausedInterval);
        }
        this.dvrPausedInterval = setInterval(() => {
          if (!playlistStore.isCurrentVideoDVR()) {
            clearInterval(this.dvrPausedInterval);
          } else if (!videoPlayerStore.state.isPlaying) {
            this.state.currentDVROffset = this.state.currentDVROffset - 1;
            this.trigger(this.state);
          }
        }, 1000);
      }
      analyticsVideoView('pauseVideo');
      this.state.playerIsPlaying = false;
      this.trigger(this.state);
      setTimeout(() => {
        this.state.playerIsPlaying = false;
        this.trigger(this.state);
      }, 500);
    } else if (!videoPlayerStore.state.isPlaying || force) {
      ariaTTSServiceStore.readText('playing video', true);
      videoPlayerActions.playVideo();
      analyticsVideoView('playVideo');
      this.state.isPlayPauseButtonFocused = true;
      this.state.playerIsPlaying = true;
      this.trigger(this.state);
    }
  },

  onFFEnterUp: function () {
    this.state.isEnterUpReceived = true;
    this.trigger(this.state);
  },

  onLongClickFF: async function () {
    this.state.isEnterUpReceived = false;
    while (!this.state.isEnterUpReceived) {
      if (this.state.isEnterUpReceived) break;
      await callWithTimer(this.onClickFF, 100);
    }
    this.state.isEnterUpReceived = false;
    this.trigger(this.state.isEnterUpReceived);
  },

  onLongClickREW: async function () {
    this.state.isEnterUpReceived = false;
    while (!this.state.isEnterUpReceived) {
      if (this.state.isEnterUpReceived) break;
      await callWithTimer(this.onClickREW, 100);
    }
    this.state.isEnterUpReceived = false;
    this.trigger(this.state.isEnterUpReceived);
  },

  onClickREW: function () {
    if (this.state.playerCurrentTime === 0) {
      return;
    }
    this.state.isRWFocused = true;
    this.state.isFFFocused = false;
    this.state.isFastForwarding = false;
    this.state.isPlayPauseButtonFocused = false;

    if (this.state.isFastForwarding === true) {
      return;
    }
    if (this.state.isRewinding === false && videoPlayerStore.state.isPlaying) {
      videoPlayerActions.pauseVideo();
      analyticsVideoView('pauseVideo');
      this.state.isRewinding = true;
      analyticsVideoView('seekVideo');
    }

    this.resetPlayerTimeout();
    this.resetResumePlayTimeout();
    if (this.state.lastDirectionPressed === 'FASTFORWARD') this.state.countOfFFRWPress = 0;
    this.state.lastDirectionPressed = 'REWIND';

    const newTime = this.state.playerCurrentTime - this.getSpeedOfFFRW();
    if (newTime * 1000 > 0) {
      this.calculateProgress(newTime);
    } else {
      this.calculateProgress(0);
    }

    ariaTTSServiceStore.readText(
      'Rewind to, ' + timeFormatter().toHmsReadString(newTime),
      false,
      true
    );

    this.state.countOfFFRWPress = this.state.countOfFFRWPress + 1;
    this.trigger(this.state);
  },

  onClickFF: function () {
    const minAllowableRemainingTimeAfterFF = 10;
    if (this.state.secondsLeftInVideo < minAllowableRemainingTimeAfterFF) {
      return;
    }
    this.state.isFFFocused = true;
    this.state.isRWFocused = false;
    this.state.isRewinding = false;
    this.state.isPlayPauseButtonFocused = false;

    if (this.state.isRewinding === true) {
      return;
    }
    if (this.state.isFastForwarding === false && videoPlayerStore.state.isPlaying) {
      videoPlayerActions.pauseVideo();
      this.state.isFastForwarding = true;
      analyticsVideoView('seekVideo');
    }

    this.resetPlayerTimeout();
    this.resetResumePlayTimeout();
    if (this.state.lastDirectionPressed === 'REWIND') this.state.countOfFFRWPress = 0;

    this.state.lastDirectionPressed = 'FASTFORWARD';

    let newTime = this.state.playerCurrentTime + this.getSpeedOfFFRW();
    const secondsRemainingAfterFF = (this.state.playerDurationInMs - newTime * 1000) / 1000;

    if (secondsRemainingAfterFF < minAllowableRemainingTimeAfterFF) {
      // If FF action will take us to less than 15 seconds of the total duration, stop it and set to 15 seconds exactly so that
      // user can't FF to the end of a video
      newTime = this.state.playerDurationInMs - minAllowableRemainingTimeAfterFF;
    }

    if (newTime * 1000 < this.state.playerDurationInMs - minAllowableRemainingTimeAfterFF) {
      this.calculateProgress(newTime);
    } else {
      this.calculateProgress(
        this.state.playerDurationInMs / 1000 - minAllowableRemainingTimeAfterFF
      );
    }

    ariaTTSServiceStore.readText(
      'Fast Forward to, ' + timeFormatter().toHmsReadString(newTime),
      false,
      true
    );

    this.state.countOfFFRWPress = this.state.countOfFFRWPress + 1;
    this.trigger(this.state);
  },

  getSpeedOfFFRW: function () {
    if (this.state.countOfFFRWPress < 5) {
      return 10;
    } else if (this.state.countOfFFRWPress >= 5 && this.state.countOfFFRWPress < 20) {
      return 20;
    } else if (this.state.countOfFFRWPress >= 20 && this.state.countOfFFRWPress < 50) {
      return 30;
    } else {
      return 60;
    }
  },

  resetPlayerTimeout: function () {
    // The player controls should disappear after a set time if the user isn't interacting with them.
    const timeoutConstant = conf.testBuild
      ? 90000
      : configDataStore.getConstant('player_controls_timeout');
    clearTimeout(this.state.playerControlsTimeout);
    this.state.playerControlsTimeout = setTimeout(() => {
      this.fadeOutPlayerControls();
    }, timeoutConstant);
  },

  resetResumePlayTimeout: function (value = 1500) {
    clearTimeout(this.playTimeout);
    this.playTimeout = setTimeout(this.resumePlay, value);
  },

  fadeOutPlayerControls: function () {
    if (!videoPlayerStore.state.isPlaying && (this.state.isFFFocused || this.state.isRWFocused)) {
      // If the user was in FF/RW when controls fade out, restart the video and move focus to play/pause
      this.state.isFFFocused = false;
      this.state.isRWFocused = false;
      this.state.isPlayPauseButtonFocused = true;
      this.state.isPlaylistFocused = false;
      this.state.isCaptionsButtonFocused = false;
      this.resetResumePlayTimeout(0);
    }
    consumptionActions.hidePlayerControls();
  },

  seekForwardDVR: function () {
    this.state.isRewinding = false;
    const newDVROffset = this.state.currentDVROffset + 15;

    if (newDVROffset >= 0) {
      playerControlsActions.jumpToLiveDVR();
      return;
    }

    if (this.state.isFastForwarding === false && videoPlayerStore.state.isPlaying) {
      videoPlayerActions.pauseVideo();
      this.state.isFastForwarding = true;
    }

    this.resetPlayerTimeout();
    this.resetResumePlayTimeout();
    analyticsVideoView('15secForward');

    let newTime = videoPlayerStore.state.playerObjectTime + 15;
    if (isNaN(newTime)) newTime = 0;
    this.state.currentDVROffset = newDVROffset;
    this.state.playerCurrentTime = newTime;
    videoPlayerActions.setPlayerObjectTime(newTime);

    if (PlatformUtils.isMagenta) {
      videoPlayerActions.jumpToTime(newTime);
    } else if (appStore.state.playerUsed === 'bitmovin' || PlatformUtils.isHTML5) {
      videoPlayerActions.jumpToTime(15);
    }
    this.calculateProgressDVR();
    this.trigger(this.state);
  },

  seekBackDVR: function () {
    this.state.isFastForwarding = false;

    if (this.state.isRewinding === false && videoPlayerStore.state.isPlaying) {
      videoPlayerActions.pauseVideo();
      this.state.isRewinding = true;
    }

    this.resetPlayerTimeout();
    this.resetResumePlayTimeout();
    analyticsVideoView('15secBackward');

    let newTime = videoPlayerStore.state.playerObjectTime - 15;
    if (isNaN(newTime)) newTime = 0;
    this.state.playerCurrentTime = newTime;
    videoPlayerActions.setPlayerObjectTime(newTime);

    if (PlatformUtils.isMagenta) {
      videoPlayerActions.jumpToTime(newTime);
    } else if (appStore.state.playerUsed === 'bitmovin' || PlatformUtils.isHTML5) {
      videoPlayerActions.jumpToTime(-15);
    }

    const maxOffset = (this.state.bitmovinDvrStart - Date.now()) / 1000;

    if (this.state.currentDVROffset - 15 < maxOffset) {
      const newOffset = maxOffset + Math.abs(this.state.currentDVROffset);
      this.state.currentDVROffset = this.state.currentDVROffset + newOffset;
    } else {
      this.state.currentDVROffset = this.state.currentDVROffset - 15;
    }

    this.calculateProgressDVR();
    this.trigger(this.state);
  },

  jumpToLiveDVR: function () {
    this.resetPlayerTimeout();
    analyticsVideoView('BackToLive');
    this.state.isLoading = true;
    this.state.isLiveFocused = false;
    videoPlayerActions.restartDVRStream();
    this.state.isBackToStartFocused = false;
    this.state.isPlayPauseButtonFocused = true;
    this.calculateProgressDVR(100);
    this.trigger(this.state);
  },

  backToStartDVR: function () {
    this.resetPlayerTimeout();
    this.resetResumePlayTimeout();
    if (appStore.state.playerUsed !== 'bitmovin') {
      this.state.currentDVROffset = -videoPlayerStore.state.duration;
      if (PlatformUtils.isPS5) this.state.playerCurrentTime = this.state.bitmovinDvrStart;
    }
    if (appStore.state.playerUsed === 'html5') {
      this.state.playerCurrentTime = 0;
    }
    videoPlayerActions.backToStartDVRStream();
    analyticsVideoView('BackToStart');
    this.calculateProgressDVR(0);
    this.trigger(this.state);
  },

  setBitmovinDvrOffset: function (current) {
    this.state.currentDVROffset = current * -1;
    this.trigger(this.state);
  },

  dvr_focusBackToStart: function () {
    this.resetPlayerTimeout();
    this.state.isBackToStartFocused = true;

    this.state.isRWFocused = false;
    this.state.isPlayPauseButtonFocused = false;
    this.state.isFFFocused = false;
    this.state.isLiveFocused = false;

    this.trigger(this.state);
  },

  dvr_focusRewind: function () {
    this.resetPlayerTimeout();
    this.state.isRWFocused = true;

    this.state.isBackToStartFocused = false;
    this.state.isPlayPauseButtonFocused = false;
    this.state.isFFFocused = false;
    this.state.isLiveFocused = false;

    this.trigger(this.state);
  },

  dvr_focusPlayPauseButton: function () {
    this.resetPlayerTimeout();
    this.state.isPlayPauseButtonFocused = true;

    this.state.isBackToStartFocused = false;
    this.state.isFFFocused = false;
    this.state.isRWFocused = false;
    this.state.isLiveFocused = false;

    this.trigger(this.state);
  },

  dvr_focusFastForward: function () {
    this.resetPlayerTimeout();
    if (this.state.currentDVROffset === 0) {
      return;
    }
    this.state.isFFFocused = true;

    this.state.isBackToStartFocused = false;
    this.state.isRWFocused = false;
    this.state.isPlayPauseButtonFocused = false;
    this.state.isLiveFocused = false;

    this.trigger(this.state);
  },

  dvr_focusLive: function () {
    this.resetPlayerTimeout();
    this.state.isLiveFocused = true;

    this.state.isBackToStartFocused = false;
    this.state.isRWFocused = false;
    this.state.isPlayPauseButtonFocused = false;
    this.state.isFFFocused = false;
    this.trigger(this.state);
  },

  handlePlaylistOnEnter(item) {
    if (!item.id || !item.playable) {
      return;
    }
    videoPlayerActions.setIsStopped();
    videoViewTrackingStore.setPlayType('manual');
    analyticsVideoView('stopVideo');
    playlistActions.setCurrentlyWatchingIndex(item);
    consumptionActions.setCurrentAsset(item);
    console.log('resumePoint 2');
    videoPlayerActions.playNewStream(item);
    consumptionActions.hideAllControls();
  },

  playVideo(selectedCard, selectedID) {
    if (!selectedCard.playable) {
      return;
    }
    if (playlistStore.isCurrentlyPlayingVideo(selectedCard) && playlistStore.isOrderedPlaylist()) {
      console.info('already playing item', selectedCard);
    } else {
      videoPlayerActions.setIsStopped();
      playlistStore.playDeeplinkPlaylistFromProduct(selectedCard.id);
      historyActions.setLastComponent({ name: 'playlist' }); // TODO: check RBMN-39138
      historyStore.pushHistory('/Consumption/', { name: 'consumption' });
    }
  },

  handleLinearPlaylistCardOnEnter(selectedCard) {
    videoViewTrackingStore.setPlayType('manual');
    analyticsVideoView('stopVideo');
    if (isLive(selectedCard)) {
      const stop = _.find(selectedCard.links, (link) => {
        return link.action === 'view';
      });
      const pageId = stop ? stop.id : selectedCard.id;
      historyActions.setLastComponent({ name: '/Consumption', id: pageId }); // TODO: check RBMN-39138
      historyStore.pushHistory('/Home/page/' + pageId, { name: 'page', id: pageId });
    } else if (!selectedCard.playable) {
      hintsActions.clearHints();
      listPageActions.reset(selectedCard.id);
      historyStore.pushHistory('/Home/page/' + selectedCard.id, {
        name: 'page',
        id: selectedCard.id
      });
    } else {
      recentlyWatchedStore.checkShowResumeDialog(selectedCard, playerControlsStore.playVideo);
    }
  }
});
