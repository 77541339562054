import Reflux from 'reflux';
import _ from 'lodash';
import PlatformUtils from '../utils/platform';
import API from '../services/api';
import { startStore } from './startStore';
import { historyStore } from './historyStore';
import { appkitAnalyticsStore, analyticsVideoView } from './appkitAnalyticsStore';
import conf from '../conf';
const Buffer = require('buffer/').Buffer;

const localStorage = PlatformUtils.sharedPlatform.localStorage();
const isServus = conf.appNamespace === 'servustv';

export const USER_RAILS = ['continue_watching', 'favorites', 'interests', 'recommender'];

export const FAVORITE_ACTION = {
  ADD: 'addFavorite',
  REMOVE: 'removeFavorite'
};

export const userAccountActions = Reflux.createActions({
  setUserLoggedIn: {},
  setUserAccountObject: {},
  login: {},
  logout: {},
  toggleIsFavorite: {},
  favoritesListUpdated: {},
  setUserEntitlements: {}
});

export const userAccountStore = Reflux.createStore({
  listenables: userAccountActions,
  state: {
    account: {},
    userFavoritesIds: [],
    isUserLoggedIn: false,
    entitlements: [],
    isRefreshingToken: false
  },

  init: function () {
    this.listenTo(startStore, this.setUserFavoritesIds);
  },

  getUserId: function () {
    return this.state.account.uid;
  },

  getToken: function () {
    return this.state.account.id_token;
  },

  setUserAccountObject: function (userLogin) {
    this.state.account = Object.assign(this.state.account, userLogin);
  },

  setUserLoggedIn: function (isLoggedIn) {
    this.state.isUserLoggedIn = isLoggedIn;
    this.trigger(this.state);
  },

  setUserEntitlements: function () {
    API.getUserEntitlements()
      .then((entitlementsInfo) => {
        if (Array.isArray(entitlementsInfo)) {
          this.state.entitlements = entitlementsInfo;
          return;
        }
        this.state.entitlements = [];
      })
      .catch((error) => {
        console.log('Entitlements: Error while getting the entitlements ', error.message);
        this.state.entitlements = [];
      });
  },

  getUserEntitlements: function () {
    return this.state.entitlements;
  },

  getFavoritesRail: function () {
    const home = _.find(startStore.state?.data?.system_views, (view) => view.id === 'home');
    const favoritesRail = _.find(home?.collections, (rail) => rail.homeRailId === 'favorites');

    return favoritesRail || {};
  },

  setUserFavoritesIds: function () {
    const userFavoritesIds = {};
    const favoritesRail = this.getFavoritesRail();
    const favorites = (favoritesRail && favoritesRail.items) || [];
    const currentFavoritesLength = favorites.length;

    if (currentFavoritesLength === this.lastFavoritesAmount) return;

    this.lastFavoritesAmount = currentFavoritesLength;

    if (Array.isArray(favorites)) {
      _.forEach(favorites, (item) => {
        userFavoritesIds[item.id] = true;
      });
    }

    this.state.userFavoritesIds = userFavoritesIds;
    this.trigger(this.state);
  },

  isCardInUserFavorites: function (itemId) {
    return this.state.userFavoritesIds[itemId];
  },

  login(account) {
    this.setUserAccountObject(account);
    this.setUserLoggedIn(true);
    this.toggleUserFavoritesOnSignIn();
    localStorage.setItem('rbtv:userLogin', JSON.stringify(this.state.account));
    appkitAnalyticsStore.identifyUser(this.getUserId());
  },

  logout() {
    this.setUserAccountObject({});
    this.setUserLoggedIn(false);
    this.removeUserRails();
    localStorage.removeItem('rbtv:userLogin');
    appkitAnalyticsStore.logoutUser();
  },

  removeUserRails() {
    let dirty = false;
    const startData = startStore.state.data;
    if (_.get(startData, 'system_views.length', 0)) {
      startData.system_views.forEach((sysview) => {
        if (sysview?.id === 'home' && _.has(sysview, 'collections')) {
          sysview.collections.forEach((collection) => {
            if (_.includes(USER_RAILS, collection?.homeRailId)) {
              dirty = true;
              collection.items = [];
              collection.meta = {};
            }
          });
        }
      });
    }

    if (dirty) startStore.forceDataUpdate(startData);
  },

  checkAccessTokenExp: function (accountInfo = this.state.account) {
    return new Promise((resolve, reject) => {
      let accessTokenExpired =
        !accountInfo || !accountInfo.id_token || accountInfo.id_token === 'undefined';

      if (
        !accessTokenExpired &&
        typeof accountInfo.id_token === 'string' &&
        accountInfo.id_token.split('.')[1]
      ) {
        accessTokenExpired =
          new Date(
            JSON.parse(Buffer.from(accountInfo.id_token.split('.')[1], 'base64')).exp * 1000
          ) < new Date();
      } else {
        accessTokenExpired = true;
      }

      if (accessTokenExpired && !this.state.isRefreshingToken) {
        this.state.isRefreshingToken = true;
        API.refreshToken(accountInfo)
          .then((accountInfo) => {
            this.state.isRefreshingToken = false;
            console.log('Account: Success refreshing the accessToken ', accountInfo);
            this.login(accountInfo);
            resolve(accountInfo);
          })
          .catch((error) => {
            this.state.isRefreshingToken = false;
            console.log('Account: Error while refreshing the accessToken ', error.message);
            this.state.isUserLoggedIn = false;
            this.trigger(this.state);
            reject(error);
          });
        if (isServus) {
          this.setUserEntitlements();
        }
      } else {
        resolve(accountInfo);
      }
    });
  },

  toggleUserFavoritesOnSignIn: function () {
    if (this.state.itemToAddToUserFavoritesOnSignIn?.item) {
      this.toggleIsFavorite(
        this.state.itemToAddToUserFavoritesOnSignIn.item,
        this.state.itemToAddToUserFavoritesOnSignIn.toggleSource
      );
      this.state.itemToAddToUserFavoritesOnSignIn = null;
      this.trigger(this.state);
    }
  },

  forceFavoriteRailUpdate: function (item, action) {
    let dirty = false;
    const startData = startStore.state.data;
    if (_.get(startData, 'system_views.length', 0)) {
      startData.system_views.forEach((sysview) => {
        if (sysview?.id === 'home' && _.has(sysview, 'collections')) {
          sysview.collections.forEach((collection) => {
            if (collection?.homeRailId === 'favorites') {
              switch (action) {
                case FAVORITE_ACTION.ADD:
                  if (!collection.items.some((n) => n.id === item.id)) {
                    dirty = true;
                    collection.items = [item, ...collection.items];
                    collection.meta.total++;
                  }
                  break;
                case FAVORITE_ACTION.REMOVE:
                  if (collection.items.some((n) => n.id === item.id)) {
                    dirty = true;
                    collection.items = collection.items.filter((n) => n.id !== item.id);
                    collection.meta.total--;
                  }
                  break;
                default:
                  console.info('Ignore fav update', action, item);
              }
            }
          });
        }
      });
    }

    if (dirty) startStore.forceDataUpdate(startData);
  },

  favoritesListUpdated: function (item, toggleSource, action) {
    analyticsVideoView(action, {
      favoriteItem: item,
      toggleType: toggleSource === 'button' ? toggleSource : 'remote'
    });
  },

  setFavorite: function (item, toggleSource) {
    if (this.isCardInUserFavorites(item.id)) return;
    this.forceFavoriteRailUpdate(item, FAVORITE_ACTION.ADD);
    API.addToUserFavorites(item.id)
      .then(() => {
        userAccountActions.favoritesListUpdated(item, toggleSource, FAVORITE_ACTION.ADD);
      })
      .catch((err) => {
        console.log('User favorite item not saved', err);
        this.forceFavoriteRailUpdate(item, FAVORITE_ACTION.REMOVE);
        // Comment dialog out for now since they cause black screen in getPage of home.jsx dialogStore.overlayShowing
        // dialogActions.showErrorMessageScreen(true, localizationStore._GET('account_favorite_add_error')); // TODO: Needs to be fixed
      });
  },

  deleteFavorite: function (item, toggleSource) {
    this.forceFavoriteRailUpdate(item, FAVORITE_ACTION.REMOVE);
    API.deleteFromUserFavorites(item.id)
      .then(() => {
        userAccountActions.favoritesListUpdated(item, toggleSource, FAVORITE_ACTION.REMOVE);
      })
      .catch((err) => {
        console.log('User favorite item not removed', err);
        this.forceFavoriteRailUpdate(item, FAVORITE_ACTION.ADD);
        // Comment dialog out for now since they cause black screen
        // dialogActions.showErrorMessageScreen(true, 'There was a problem removing your favorite. Please try again later.');
      });
  },

  toggleIsFavorite: function (item, toggleSource) {
    if (
      item.content_type === 'linear-channel' ||
      item.content_type === 'format' ||
      item.content_type === 'subchannel' ||
      item.type === 'view-more'
    )
      return;

    if (!this.state.isUserLoggedIn) {
      this.state.itemToAddToUserFavoritesOnSignIn = { item, toggleSource };
      historyStore.state.forceNextGoBack = true;
      historyStore.pushHistory('/Home/account', { name: 'account' });
      this.trigger(this.state);
      return;
    }

    this.checkAccessTokenExp()
      .then(() => {
        if (this.isCardInUserFavorites(item.id)) {
          this.deleteFavorite(item, toggleSource);
        } else {
          this.setFavorite(item, toggleSource);
        }
      })
      .catch((error) => {
        console.log('toggle is favorite failed', error);
      });
  },

  checkIsUserLoggedIn: function () {
    let accountInfo = { access_token: null, refresh_token: null };
    const accountInfoJSON = localStorage.getItem('rbtv:userLogin');
    try {
      if (accountInfoJSON) {
        accountInfo = JSON.parse(accountInfoJSON);
      }
    } catch (error) {
      console.log('Account: LocalStorage contains invalid object ', error.message);
      userAccountActions.setUserLoggedIn(false);
      return;
    }
    if (
      accountInfo &&
      typeof accountInfo === 'object' &&
      accountInfo.access_token &&
      accountInfo.refresh_token
    ) {
      this.setUserAccountObject(accountInfo);
      this.checkAccessTokenExp(accountInfo)
        .then((accountInfo) => {
          userAccountActions.login(accountInfo);
          if (isServus) {
            this.setUserEntitlements();
          }
        })
        .catch((error) => {
          console.log(
            'Account: access token was not refreshed on start, user logged out ',
            error.message
          );
        });
    } else {
      userAccountActions.setUserLoggedIn(false);
    }
  }
});
