import superagent from 'superagent';
import uuid from 'uuid/v4';
import isEmpty from 'lodash/isEmpty';
import PlatformUtils from '../utils/platform';
import islive from '../utils/isLive';
import { appStore, appActions } from '../reflux/appStore';
import conf from '../conf';
import { configDataStore } from '../reflux/configDataStore';
import { userAccountStore } from '../reflux/userAccountStore';
import { settingsStore } from '../reflux/settingsStore';
import { epgStore } from '../reflux/epgStore';
import Q from './queue';

let cancellableCollectionRequests = [];
let lastCancelTimestamp = Date.now();
const servusLocale = ['at', 'de', 'en'];
const isServus = conf.appNamespace === 'servustv';

const localStorage = PlatformUtils.sharedPlatform.localStorage();
const platformId = PlatformUtils.getDeviceTrackingPlatform();

function addLocaleParam(url, localeOverride) {
  const deviceLanguage = PlatformUtils.getModifiedDeviceLanguage();
  let localeParam = deviceLanguage;
  if (isServus && servusLocale.indexOf(deviceLanguage) === -1) localeParam = 'de';

  const localeToUse = localeOverride || localeParam;
  return url + (url.indexOf('?') === -1 ? '?locale=' + localeToUse : '&locale=' + localeToUse);
}

function addNamespaceParamToURL(route) {
  route.searchParams.append('namespace', configDataStore.getConstant('api_namespace'));
  return route;
}

function addNamespaceParam(url) {
  if (url && url.includes('namespace=')) return url;
  const NAMESPACE_PARAM = configDataStore.getConstant('api_namespace');
  return (
    url +
    (url.indexOf('?') === -1 ? '?namespace=' + NAMESPACE_PARAM : '&namespace=' + NAMESPACE_PARAM)
  );
}

function addPersonalizationParams(route) {
  const userId = userAccountStore.getUserId();
  if (userId) {
    route.searchParams.append('user_id', userId);
  }

  route.searchParams.append('client_id', appStore.state.uid);
  route.searchParams.append('platform_id', platformId);
  return route;
}
function addVideoQualityParams(route) {
  // params are appended to an URL structure
  const storedParams = appStore.getDeviceAudioVideoAttributes();
  if (storedParams) {
    if (storedParams.model) route.searchParams.append('model', storedParams.model || null);
    if (storedParams.os_version)
      route.searchParams.append('os_version', storedParams.os_version || null);
    if (storedParams.codec) {
      route.search += (route.search ? '&' : '?') + 'codec=' + storedParams.codec;
    }
    if (storedParams.format) route.searchParams.append('format', storedParams.format || null);
    if (storedParams.quality) route.searchParams.append('quality', storedParams.quality || null);
  }
  return route;
}

function stringUrlToHttp(url) {
  return url.replace('s://', '://').replace('S://', '://');
}

export default class Api {
  static getVideoUrl(productID, dashAvailable) {
    let url = null;
    if (!productID) {
      console.error('getVideoUrl: No productID passed');
      return;
    }
    if (PlatformUtils.supportsDMSv5()) {
      console.log('API Get Video URL DMSv5');
      const hlsUrl =
        configDataStore.state.configData.hls_base_url_v5 || 'https://dms.redbull.tv/v5/destination';
      const fullUrl =
        hlsUrl +
        '/' +
        configDataStore.getConstant('api_namespace') +
        '/' +
        productID +
        '/' +
        PlatformUtils.getCategory() +
        '/' +
        PlatformUtils.getOSFamily() +
        '/' +
        appStore.getCountryCode() +
        '/' +
        PlatformUtils.getDeviceLanguage().replace('-', '_') +
        `/playlist${dashAvailable ? '.mpd' : '.m3u8'}`;
      url = new URL(fullUrl);
      url = addPersonalizationParams(url);
      url = addVideoQualityParams(url);
    } else {
      console.log('API Get Video URL DMSv3');
      const authToken = appStore.getAuthToken();
      const fileExt = PlatformUtils.isMagenta ? '.mpd' : dashAvailable ? '.mpd' : '.m3u8';
      const hlsUrl = PlatformUtils.isSkyQ
        ? stringUrlToHttp(configDataStore.state.configData.hls_base_url)
        : configDataStore.state.configData.hls_base_url || 'https://dms.redbull.tv/v3';
      const fullUrl = hlsUrl + '/' + productID + '/' + authToken + '/playlist' + fileExt;
      url = new URL(fullUrl);
      url = addPersonalizationParams(url);
      url = addNamespaceParamToURL(url);
    }
    console.log('API Get Video Url from DMS with: ', decodeURIComponent(url.toString()));
    return url.toString();
  }

  static getLocalAPI() {
    const localStorage = PlatformUtils.sharedPlatform.localStorage();
    return localStorage.getItem('rbtv:testApiUrl') || 'https://api.redbull.tv';
  }

  static getApiUrl() {
    return conf.testBuild ? Api.getLocalAPI() : conf.apiUrl;
  }

  static getLocalAccountAPI() {
    const localStorage = PlatformUtils.sharedPlatform.localStorage();
    return localStorage.getItem('rbtv:testApiAccountUrl') || 'https://account.redbull.com/api/v1';
  }

  static getAccountUrl() {
    let baseUrl = configDataStore.getConstant('account_auth0_base_url');
    if (!baseUrl) {
      baseUrl = configDataStore.getConstant('account_base_url');
    }
    return baseUrl + '/api/v1';
  }

  static getLocalProxyUrl() {
    return (
      localStorage.getItem('rbtv:testApiProxyUrl') ||
      'https://adproxy.redbull.com/adproxy/stv/v2.0/'
    );
  }

  static getProxyUrl() {
    return conf.testBuild
      ? Api.getLocalProxyUrl()
      : configDataStore.getConstant('display_ads')?.url;
  }

  static getIdType() {
    switch (platformId) {
      case 'xbox':
        return 'msai';
      case 'samsungtv':
        return 'tifa';
      case 'vidaatv':
        return 'vaid';
      default:
        return undefined;
    }
  }

  static getMsid() {
    switch (platformId) {
      case 'xbox':
        return '9PHZ4LRJNCWH';
      case 'samsungtv':
        return 'MAonQ33bTs.ServusTV';
      default:
        return `com.servustv.${platformId}`;
    }
  }

  static getAdTagUrl(
    assetId,
    { os = 'html5', country = 'at', optout = '0', streaming_type = 'vod' } = {},
    currentAsset
  ) {
    if (configDataStore.getConstant('display_ads_source') === 'gam') {
      const appEnv = localStorage.getItem('rbtv:env');
      const prefixURL = configDataStore.getConstant('display_ads_gam')?.url + '?';
      // vid is defined in the final proxyURL at the bottom
      const tfcd = 0; // static
      const npa = 0; // static
      const sz = '640x480'; // static dimensions, we don't use these anywhere
      const cmsid = configDataStore.getConstant('display_ads_gam')?.cmsid;
      const adRule = 1; // static
      const adType = 'standardvideo'; // static, remove to allow skippable videos to be served
      const wta = 1; // static
      const gdfpReq = 1; // static
      const output = 'xml_vast4'; // static
      const unviewedPositionStart = 1; // static
      const env = 'vp'; // static
      const plcmt = 1; // static
      const vpmute = 0; // static
      const staticValues = `&tfcd=${tfcd}&npa=${npa}&sz=${sz}&cmsid=${cmsid}&ad_rule=${adRule}&ad_type=${adType}&wta=${wta}&gdfp_req=${gdfpReq}&output=${output}&unviewed_position_start=${unviewedPositionStart}&env=${env}&plcmt=${plcmt}&vpmute=${vpmute}`;
      // derived, non-static values
      // is_lat is defined in the final proxyURL at the bottom
      const gdpr = 1;
      const ltd = optout !== '0' ? `&ltd=${optout}` : ''; // only gets included if it exists, don't want =0 or to leave the field empty.  Basically does the same thing as is_lat afaik
      const iu = encodeURIComponent(
        `${configDataStore.getConstant('display_ads_gam')?.iu}/${
          appEnv && appEnv !== 'Production' ? 'test/' : ''
        }CTV/${platformId}`
      );
      const descriptionURL = encodeURIComponent(currentAsset?.share_url); // consumptionStore.state.currentAssetObject
      const correlator = Math.floor(Math.random() * 10000000000000000); // generates a random number for the correlator.  Not perfect but because of PS5 we can't rely on the IMA SDK to do this for us
      const userEntitlements = userAccountStore.getUserEntitlements();
      const entitlements = userEntitlements.length ? userEntitlements.join('%2C') : '';
      const customParams = `stvon_ctv_platform%3D${platformId}%26streaming_type%3D${streaming_type}%26entitlements%3D${entitlements}`;
      const idtype = this.getIdType() ? `&idtype=${this.getIdType()}` : ''; // only gets included if it exists, don't want to leave the field empty in the URL
      const msid = this.getMsid() ? `&msid=${this.getMsid()}` : '';
      const an = 'servustv'; // need to confirm
      const rdid = appStore.state.uid;
      const derivedValues = `&vid=${assetId}&cust_params=${customParams}&url=${descriptionURL}&description_url=${descriptionURL}&iu=${iu}&gdpr=${gdpr}&is_lat=${optout}${ltd}&rdid=${rdid}${idtype}${msid}&an=${an}&correlator=${correlator}`;
      const proxyURL = `${prefixURL}${derivedValues}${staticValues}`;
      console.log('GAM URL:', proxyURL);
      return proxyURL;
    }

    // const appSessionObject = appStore.getSessionObject();
    // let suffix = `os_family=${appSessionObject.os_family}&country_code=${appSessionObject.country_code}&optout=${cookieConsentStore.state.cookieConsent['ads']}`
    let suffix = `os_family=${os}&country_code=${country}&optout=${optout}&streaming_type=${streaming_type}`;
    if (conf.testBuild && settingsStore.state.settings?.enableAlwaysShowAds) {
      suffix += '&playertest=true';
    }
    const isUserLoggedIn = userAccountStore?.state?.isUserLoggedIn;
    const userEntitlements = userAccountStore.getUserEntitlements();
    suffix += `&entitlements=${
      isUserLoggedIn && userEntitlements.length ? userEntitlements.join(',') : ''
    }`;
    const proxyURL = `${new URL(assetId, Api.getProxyUrl())}?${suffix}`;
    return proxyURL;
  }

  static getSessionApiSuffix() {
    // According to https://itdoc.redbull.com/display/RDPUS/Category+and+OS+Family+Options+DMS+v5 but set_top not accepted

    const category = PlatformUtils.getCategory();
    const os_family = PlatformUtils.isTizen ? 'tizen' : PlatformUtils.getOSFamily();

    return `category=${category}&os_family=${os_family}`;
  }

  static getRaw(url) {
    url = addNamespaceParam(url);
    return Q(() => {
      return superagent.get(url).then((d) => {
        return d.text;
      });
    });
  }

  static getMarkdown(url) {
    return Q(() => {
      return new Promise(function (resolve, reject) {
        superagent
          .get(url)
          .accept('text/markdown')
          .end((err, res) => {
            if (err) {
              const error = res ? res.status : err;
              reject(error);
            } else {
              resolve(res.text);
            }
          });
      });
    });
  }

  static async getVideoQualityParams() {
    // params will be added in the query form to string url
    const startParams = await PlatformUtils.sharedPlatform.getDeviceAudioVideoAttributes();
    if (startParams) {
      const query = ''.concat(
        startParams.model ? `&model=${startParams.model}` : '',
        startParams.os_version ? `&os_version=${startParams.os_version}` : '',
        startParams.codec ? `&codec=${startParams.codec}` : '',
        startParams.format ? `&format=${startParams.format}` : '',
        startParams.quality ? `&quality=${startParams.quality}` : ''
      );
      return query;
    } else {
      return '';
    }
  }

  static async getSessionData(localeOverride) {
    // Doesn't use resolveDataPromise because the API team wants us to do a POST not a GET for /session
    const qualityParams = await Api.getVideoQualityParams();
    let route = Api.getApiUrl() + '/v3/session?' + Api.getSessionApiSuffix() + qualityParams; //
    return Q(() => {
      return new Promise(function (resolve, reject) {
        route = addLocaleParam(route, localeOverride);
        console.log('API Get Session Data with: ', route);
        superagent
          .get(route, {})
          .set(
            settingsStore.state.settings.spoofCountry
              ? { 'x-user-country': settingsStore.state.settings.spoofNameCountry }
              : {}
          )
          .end((err, res) => {
            if (err) {
              const error = res ? res.status : err;
              reject(error);
            } else {
              appActions.setDeviceAudioVideoAttributes(res.body);
              if (res.body && res.body.token) {
                localeOverride
                  ? appStore.setDeeplinkAuthToken(res.body)
                  : appActions.setAuthToken(res.body);
                resolve(res.body);
              } else {
                console.log(res.body);
              }
            }
          });
      });
    });
  }

  static getConfigData() {
    const configVersion = conf.appNamespace === 'rbtv' ? 'v1' : 'v1_stv';
    let route = new URL(Api.getApiUrl() + '/v3/configuration/smarttv/' + configVersion);
    route = addPersonalizationParams(route);

    return Api.resolveDataPromise(route.toString(), true);
  }

  static resolveDataPromise(route, noAuth, isRetry, type = 'GET', headers = {}) {
    return Q(() => {
      return new Promise(function (resolve, reject) {
        if (!route) {
          console.warn(`No route registered`, route, noAuth, isRetry, type, headers);
          const error = 'cancelled';
          reject(error);
          return;
        }

        // eslint-disable-next-line no-prototype-builtins
        const hasUserToken = headers.hasOwnProperty('Authorization-Uim');

        if (!noAuth) {
          route = addNamespaceParam(route);
          headers.Authorization = appStore.getDeeplinkAuthToken() || appStore.getAuthToken();
          if (isRetry && hasUserToken) {
            headers['Authorization-Uim'] = userAccountStore.getToken();
          }
        }

        superagent(type, route)
          .set(headers)
          .end(async (err, res) => {
            const is40x = res && (res.status === 401 || res.status === 403);
            if (is40x && !isRetry) {
              await Api.getSessionData();
              if (hasUserToken) {
                try {
                  await userAccountStore.checkAccessTokenExp();
                } catch (expError) {
                  console.warn('Token in LocalMemory not valid anymore.', expError);
                }
              }
              // If we get an Unauthorized error, it may be due to headers being stripped during a 307 redirect.
              // So retry the redirected request directly ONCE - with headers re-attached
              // If we have already retried once, don't retry again. Don't want to get stuck in a loop against a 403ing endpoint
              resolve(Api.resolveDataPromise(res.xhr.responseURL, null, true, type, headers));
              return;
            }

            if (err) {
              let error;
              if (res) {
                error = res.status;
              } else {
                console.warn('Request returned error without status', err);
              }
              reject(error);
            } else {
              if (res.body) {
                resolve(res.body);
              } else if (res.text) {
                resolve(res.text);
              }
            }
          });
      });
    });
  }

  /**
   * get settings information from API
   * @returns {Promise}
   */
  static getSettingsInformation(route, useWebLinks) {
    if (useWebLinks) {
      return Api.getMarkdown(route, true);
    } else {
      return Api.resolveDataPromise(route, true);
    }
  }

  static getAccountInfo() {
    let route = Api.getAccountUrl() + '/activation/new-token';
    route = addNamespaceParam(route);
    return Api.resolveDataPromise(route, true);
  }

  static pollAccountStatus(pollId) {
    const route = Api.getAccountUrl() + '/activation/poll/' + pollId;
    return Api.resolveDataPromise(route, true);
  }

  static refreshToken(account) {
    return Q(() => {
      const route = Api.getAccountUrl() + '/uim-session/refresh';
      return superagent
        .post(route)
        .set('Authorization', account.id_token)
        .send({ refresh_token: account.refresh_token })
        .then((response) => {
          return response.body;
        });
    });
  }

  static getUserBookmarks(productId) {
    const namespaceStr = isServus ? '?&namespace=stv' : '';

    return Q(() => {
      let route =
        configDataStore.getConstant('bookmarks_base_url') +
        '/' +
        userAccountStore.getUserId() +
        (productId ? '/' + productId : '') +
        namespaceStr;
      route = addNamespaceParam(route);
      return superagent
        .get(route)
        .set('Authorization-Uim', userAccountStore.getToken())
        .then((res) => {
          if (res.body) {
            return res.body;
          } else if (res.text) {
            return res.text;
          }
        })
        .catch((err) => {
          console.warn(err);
          // This endpoint currently throws a 404 rather than an empty result if there is no bookmark for the ID
          return {};
        });
    });
  }

  static getUserInterests() {
    return Q(() => {
      const route =
        configDataStore.getConstant('actions_service_base_url') +
        '/v2/actions/' +
        configDataStore.getConstant('api_namespace') +
        '/users/' +
        userAccountStore.getUserId() +
        '/interests';
      return superagent
        .get(route)
        .set('Authorization', userAccountStore.getToken())
        .set('Authorization-Uim', userAccountStore.getToken())
        .then((res) => {
          if (res.body) {
            return res.body;
          } else if (res.text) {
            return res.text;
          }
        })
        .then((res) => {
          return Object.keys(res?.items || {}).join(',');
        })
        .catch((err) => {
          console.log(err.status);
          throw new Error(err.status);
        });
    });
  }

  static addToUserFavorites(itemId) {
    let route =
      configDataStore.getConstant('favorites_base_url') +
      '/actions/' +
      configDataStore.getConstant('api_namespace') +
      '/users/' +
      userAccountStore.getUserId() +
      '/favorites/' +
      itemId;
    route = addNamespaceParam(route);
    return superagent
      .post(route)
      .set('Authorization', appStore.getAuthToken())
      .set('Authorization-Uim', userAccountStore.getToken())
      .ok((res) => res.status === 200 || res.status === 204);
  }

  static deleteFromUserFavorites(itemId) {
    return Q(() => {
      let route =
        configDataStore.getConstant('favorites_base_url') +
        '/actions/' +
        configDataStore.getConstant('api_namespace') +
        '/users/' +
        userAccountStore.getUserId() +
        '/favorites/' +
        itemId;
      route = addNamespaceParam(route);
      return superagent
        .delete(route)
        .set('Authorization', appStore.getAuthToken())
        .set('Authorization-Uim', userAccountStore.getToken())
        .ok((res) => res.status === 200 || res.status === 204);
    });
  }

  static getProduct(id) {
    const route = Api.getApiUrl() + '/products/v4/' + id;
    return Api.resolveDataPromise(route);
  }

  /**
   * Get playlist data object by ID
   * @param playlistID
   * @returns {Promise}
   */
  static getPlaylist(playlistID, page = 1, pageSize, includes) {
    const offset = pageSize ? (page - 1) * pageSize : 0;
    let route = new URL(Api.getApiUrl() + '/playlists/v4/' + playlistID);
    route.searchParams.append('offset', offset);
    if (pageSize) {
      route.searchParams.append('limit', pageSize);
    }
    if (includes) route.searchParams.append('includes', includes);
    route = addPersonalizationParams(route);

    return Api.resolveDataPromise(route.toString());
  }

  /**
   * Search query
   * @param {string} text - query string
   * @returns {Promise}
   */
  static search(text, page = 1, pageSize, collectionType) {
    const offset = pageSize ? (page - 1) * pageSize : 0;
    let route = new URL(Api.getApiUrl() + '/v3/search/');

    route.searchParams.append('q', text);
    route.searchParams.append('offset', offset);
    if (pageSize) {
      route.searchParams.append('limit', pageSize);
    }
    if (collectionType) {
      route.searchParams.append('collection_type', collectionType);
    }
    route = addPersonalizationParams(route);

    return Api.resolveDataPromise(route.toString());
  }

  static searchAutosuggest(text) {
    const route = Api.getApiUrl() + '/v3/search/suggest' + '?q=' + text;
    return Api.resolveDataPromise(route);
  }

  /* Get Electronic Program Guide (EPG)
   * @returns {Promise}
   */
  static getEPG(id) {
    if (!id) {
      id = PlatformUtils.getDefaultLinearID();
    }
    const route = Api.getApiUrl() + '/v3/guides/' + id;
    console.log('EPG - ' + route);
    return Api.resolveDataPromise(route);
  }

  static getFullEPG(id) {
    if (!id) {
      id = PlatformUtils.getDefaultLinearID();
    }
    const route = Api.getApiUrl() + '/v3/guides/' + id + '?complete=true';
    return Api.resolveDataPromise(route);
  }

  static getAssetMetadata(productID) {
    let url = null;
    if (PlatformUtils.supportsDMSv5()) {
      console.log('API Metadata DMSv5');
      const hlsUrl =
        configDataStore.state.configData.hls_base_url_v5 || 'https://dms.redbull.tv/v5/destination';
      url =
        hlsUrl +
        '/' +
        configDataStore.getConstant('api_namespace') +
        '/' +
        productID +
        '/' +
        PlatformUtils.getCategory() +
        '/' +
        PlatformUtils.getOSFamily() +
        '/' +
        appStore.getCountryCode() +
        '/' +
        PlatformUtils.getDeviceLanguage().replace('-', '_') +
        '/playlist.json';
    } else {
      console.log('API Metadata DMSv3');
      const authToken = appStore.getAuthToken();
      const hlsUrl = configDataStore.state.configData.hls_base_url || 'https://dms.redbull.tv/v3';
      url =
        hlsUrl +
        '/' +
        productID +
        '/' +
        authToken +
        '/playlist.json?namespace=' +
        configDataStore.getConstant('api_namespace');
    }
    console.log('API Get Asset Metadata with: ', url);
    return superagent.get(url);
  }

  static fireJSONBeacon(route) {
    return Api.getRaw(route);
  }

  static getManifestData(id, dashAvailable) {
    return Api.getRaw(Api.getVideoUrl(id, dashAvailable));
  }

  static prepareCollectionRequestByReference(collection, offset, limit) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve) {
      const isLogin = userAccountStore?.state?.isUserLoggedIn;
      if (isLogin) {
        try {
          await userAccountStore.checkAccessTokenExp();
        } catch (expError) {
          console.warn('Token in LocalMemory not valid anymore.', expError);
        }
      }

      const values = {
        user_id: userAccountStore.getUserId(),
        user_token: userAccountStore.getToken(),
        user_interests: undefined,
        session_token: appStore.getDeeplinkAuthToken() || appStore.getAuthToken(),
        limit,
        offset,
        api_namespace: configDataStore.getConstant('api_namespace'),
        client_id: appStore.state.uid
      };

      if (isLogin) {
        switch (collection?.homeRailId) {
          case 'interests':
            try {
              const tags = await Api.getUserInterests();

              if (!isEmpty(tags)) {
                values.user_interests = tags;
              }
            } catch (error) {
              console.log(error);
            }
            break;
          case 'favorites':
            values.offset = 0;
            values.limit = 1000;
            break;
          default:
            break;
        }
      }

      const goodValues = Object.keys(values).filter((key) => values[key] !== undefined);

      function normalize(string) {
        return goodValues.reduce((prev, key) => {
          return prev.replace(`{{${key}}}`, values[key]);
        }, string);
      }

      const { params, placeholders, request_type: type = 'GET' } = collection.request_data;

      // Check placeholders
      const missingRequiredParam = placeholders.find((p) => p.required && !values[p.id]);
      if (missingRequiredParam !== undefined) {
        return resolve([]);
      }

      // Find and replace path/url
      const url = new URL(normalize(collection.request_data.url));
      // Add queries
      params
        .filter((p) => p.type === 'query')
        // eslint-disable-next-line array-callback-return
        .map(({ name, value }) => {
          // eslint-disable-next-line no-prototype-builtins
          if (!values.hasOwnProperty(name) || values[name] !== undefined) {
            url.searchParams.append(name, normalize(value));
          }
        });

      // Generate headers
      const headers = params
        .filter((p) => p.type === 'header')
        .reduce((prev, { name, value }) => {
          prev[name] = normalize(value);
          return prev;
        }, {});

      return resolve([url.toString(), true, false, type, headers]);
    });
  }

  static prepareCollectionRequestById(collectionId, offset, limit) {
    let route = new URL(Api.getApiUrl() + '/collections/v4/' + collectionId);
    route.searchParams.append('offset', offset);
    if (limit) {
      route.searchParams.append('limit', limit);
    }
    route = addPersonalizationParams(route);
    route = addLocaleParam(route.toString());
    return [route];
  }

  static async getCollection(collection, page = 1, limit) {
    const offset = limit ? (page - 1) * limit : 0;
    const req =
      collection?.request_data !== undefined
        ? await Api.prepareCollectionRequestByReference(collection, offset, limit)
        : Api.prepareCollectionRequestById(collection.id, offset, limit);
    return Q(() => {
      return new Promise((resolve, reject) => {
        Api.resolveDataPromise(...req)
          .then((update) => {
            const data = {
              ...collection,
              ...update,
              id: collection.id
            };

            if (Array.isArray(data.items)) {
              data.items = data.items.map((item) => {
                if (item?.id) {
                  item.isLive = islive(item);
                  item.isChannel =
                    item.content_type === 'channel' || item.content_type === 'subchannel';
                  item.isLinear = item.subchannel_type === 'linear';
                }

                return item;
              });
            }

            if (conf.appNamespace === 'rbtv' && data?.homeRailId === 'hero_channels') {
              epgStore.loadLinearChannelsCompleted(data);
            }

            resolve(data);
          })
          .catch(reject);
      });
    });
  }

  static cancelCollectionRequests() {
    lastCancelTimestamp = Date.now();
    cancellableCollectionRequests.forEach(({ reject }) => {
      const error = 'cancelled';
      reject(error);
    });
    cancellableCollectionRequests = [];
  }

  static getCollectionCancellable(collection, page = 1, pageSize, spacing = 0) {
    const timestamp = Date.now();
    return Q(() => {
      return new Promise(function (resolve, reject) {
        if (timestamp < lastCancelTimestamp) {
          console.log('CANCELLING QUEUED REQUEST');
          const error = 'cancelled';
          return reject(error);
        }

        const requestObject = { reject };
        cancellableCollectionRequests.push(requestObject);

        setTimeout(() => {
          if (timestamp < lastCancelTimestamp) {
            console.log('CANCELLING QUEUED REQUEST');
            const error = 'cancelled';
            return reject(error);
          }

          const requestId = uuid();
          Api.getCollection(collection, page, pageSize)
            .then((coll) => {
              cancellableCollectionRequests = cancellableCollectionRequests.filter(
                (r) => r.requestId !== requestId
              );
              resolve(coll);
            })
            .catch(reject);

          requestObject.requestId = requestId;
        }, spacing);
      });
    });
  }

  static openBookmarkWebSocket() {
    if (PlatformUtils.supportsWebSockets()) {
      let route =
        configDataStore.getConstant('bookmarks_socket_url') +
        '/save?uid=' +
        userAccountStore.getUserId() +
        '&token=' +
        userAccountStore.getToken();
      route = addNamespaceParam(route);
      const socket = new WebSocket(route);
      socket.onmessage = function (event) {
        console.info('WS event', event.data);
      };
      socket.onopen = function (event) {
        console.info('WS opened', event);
      };
      socket.onerror = function (event) {
        console.error('WS error', event.data);
      };
      socket.onclosed = function (event) {
        console.info('WS closed', event);
      };
      return socket;
    } else {
      return {};
    }
  }

  static sendBookmark(websocket, bookmarkData) {
    if (PlatformUtils.supportsWebSockets()) {
      bookmarkData.version = 'v1.0';
      bookmarkData.userId = userAccountStore.getUserId();
      websocket.send(JSON.stringify(bookmarkData));
    } else {
      const namespaceStr = isServus ? '?&namespace=stv' : '';

      const route =
        configDataStore.getConstant('bookmarks_base_url') +
        '/' +
        userAccountStore.getUserId() +
        '/' +
        bookmarkData.productId +
        namespaceStr;
      superagent
        .post(route)
        .set('Authorization-Uim', userAccountStore.getToken())
        .send(bookmarkData)
        .end((err, res) => {
          console.log('Bookmark POST response', err, res, route);
        });
    }
  }

  static addSamsungCWContent(addParams) {
    const route = 'http://localhost:9013/?module=EBHelper&func=AddItem';

    return superagent
      .get(route)
      .set(addParams)
      .end((err, res) => {
        console.log('Samsung CW Add response', err, res, addParams);
      });
  }

  static removeSamsungCWContent(removeParams) {
    const route = 'http://localhost:9013/?module=EBHelper&func=DeleteItem';

    return superagent
      .get(route)
      .set(removeParams)
      .then((response) => {
        console.log('Samsung CW Remove response', response);
      });
  }

  static getSamsungCWContent() {
    const route = 'http://localhost:9013/?module=EBHelper&func=GetItem';

    return superagent.get(route).then((response) => {
      console.log('Samsung CW response', response);
      return response && response.text && JSON.parse(response.text);
    });
  }

  static sendGAMeasurementProtocolHit(hitData) {
    if (!hitData) {
      return;
    }
    const route = conf.testBuild
      ? 'https://rbmh-turntable.appspot.com/test_pubsub_smart_tv'
      : 'http://google-analytics.com/collect';
    return superagent.post(route).send(hitData);
  }

  static getUserEntitlements() {
    return Q(() => {
      const route = Api.getAccountUrl() + '/entitlements/' + userAccountStore.getUserId();

      return superagent
        .get(route)
        .set('Authorization', userAccountStore.getToken())
        .then((res) => {
          if (res.body) {
            return res.body;
          }
        })
        .catch((err) => {
          console.log(err.status);
          throw new Error(err.status);
        });
    });
  }

  static getGfkConfigData({ productID, content_type, statusCode } = {}) {
    const gfk_settings = configDataStore.getConstant('gfk_settings');
    console.log('gfk api', content_type);
    const phase = content_type === 'live_program' ? '?phase=' + statusCode : '';
    const gfkBaseUrl = gfk_settings?.base_url || 'https://gfk-metadata.redbull.com/gfk/v1.0/stv';

    const route = `${gfkBaseUrl}/${productID}/${content_type + phase}`;
    return Api.resolveDataPromise(route.toString(), true);
  }
}
