import Reflux from 'reflux';
import conf from '../conf';
import _ from 'lodash';
import PlatformUtils from '../utils/platform.js';
import ErrorReporter from '../utils/error-reporter';
import { consumptionStore, consumptionActions } from './consumptionStore';
import { userAccountStore } from './userAccountStore';
import { playlistStore } from './playlistStore';
import { captionStore, captionActions } from './captionStore';
import { videoPlayerStore, videoPlayerActions } from './videoPlayerStore';
import { settingsStore } from './settingsStore';
import { exitStore } from './exitStore';
import { assetMetadataStore } from './assetMetadataStore';
import { configDataStore, configDataActions } from './configDataStore';
import { videoAnalyticsStore as videoViewTrackingStore } from './videoViewTrackingStore';
import { Analytics } from '@rbna/analytics';
import { homeStore } from './homeStore';
import { appStore } from './appStore';
import { googleAnalyticsStore } from './googleAnalyticsStore';
import { getDefaultContext, padToFive } from '../utils/analytics';
import uuid from 'uuid/v4';

const isServus = conf.appNamespace === 'servustv';

const defaultConfig = {
  appName: isServus ? 'servustv' : conf.appNamespace,
  env: conf.testBuild ? 'qa' : 'prod',
  platform: 'smarttv'
};

function eventHook(payload) {
  if (!payload.properties) payload.properties = {};

  // Add session metadata for debugging
  // console.log('appkitAnalyticsStore - payload.properties.session_id: %s', payload.properties.session_id);
  const session = {
    id: this.session.sessionID,
    startTime: this.session.sessionStartTime,
    lastRecordedTime: this.session.lastRecordedTime
  };

  payload.context = _.merge({ session }, getDefaultContext(), payload.context);

  return payload;
}

const defaultState = {
  loading: false,
  initialized: false,
  appkitLoaded: false,
  hitCounters: null,
  currentMaxHitCount: 0,

  // others
  analyticsEventsDisabled: [],
  beaconSettings: [],
  currentBeaconInterval: null,
  currentPageId: null,
  currentPageViewId: null,
  deeplinkSource: 'unspecified',
  isGoogleAnalyticsEnabled: null,
  playType: null,
  previousVideoViewId: null,
  videoViewId: null
};

class AppkitAnalyticsStore extends Reflux.Store {
  /**
   * @property {AppkitAnalyticsStore} singleton
   */
  constructor() {
    super();
    this.instance = new Analytics().setEventHook(eventHook);

    this.state = defaultState;

    this.listenTo(configDataActions.setConfigData, this.initAppkit);
    this.listenTo(exitStore, this.handleExit);
    this.listenTo(videoPlayerActions.videoEnd, this.handleEndOfVideo);
    this.listenTo(captionActions.switchWebvttCaptions, this.handleCaptionSwitch);
    this.listenTo(
      consumptionActions.updateContentObjectForEPG,
      this.listenToUpdateContentObjectForEPG
    );
    this.listenTo(
      consumptionActions.setCurrentAssetCompleted,
      this.listenToSetCurrentAssetCompleted
    );
  }

  getSessionFields() {
    return {
      site_type: 'app', // Site Type
      referral: 'unspecified', // Referral
      user_agent: navigator.userAgent, // User Agent
      client_id: appStore.state.uid, // Client ID
      logged_in_status: userAccountStore.state.isUserLoggedIn ? 'true' : 'false', // Logged In Status
      internal_referral: 'unspecified', // Internal Referrer
      user_id: userAccountStore.state.isUserLoggedIn ? userAccountStore.getUserId() : 'unspecified', // User ID
      campaign_id: 'unspecified' // Campaign ID
    };
  }

  listenToUpdateContentObjectForEPG(data) {
    // Updating of the Contextual tab is considered a new screenView
    console.log('appkitAnalyticsStore - SA: new EPG contextual object', data);

    googleAnalyticsStore.screenView('contextual', { id: data.id });
    this.screenView('contextual', { id: data.id });
  }

  listenToSetCurrentAssetCompleted(data) {
    console.log('appkitAnalyticsStore - ---appkit1', playlistStore.state.currentlyWatchingIndex);
    // Updating of the Contextual tab is considered a new screenView
    // console.log('appkitAnalyticsStore - RTM: new contextual object', data);
    const screenProperties = {
      id: data.id,
      playlistPositionId: playlistStore.state.currentlyWatchingIndex
    };

    const nextState = {
      previousVideoViewId: this.state.videoViewId,
      videoViewId: uuid(),
      playType: videoViewTrackingStore.playType
    };

    googleAnalyticsStore.screenView('contextual', screenProperties);
    this.screenView('contextual', screenProperties);

    Object.assign(googleAnalyticsStore.state, nextState);
    this.setState(nextState);

    let cps = consumptionStore.state.currentAssetObject || {};
    let title = playlistStore.isLinearPlaylist() ? 'linear' : cps.title;
    let id = playlistStore.isLinearPlaylist() ? 'linear' : cps.id;
    let params = {
      playerLoad: true,
      id: id,
      videoTitle: title,
      absolutePosition: 0,
      channel:
        cps && cps.status && cps.status.code === 'live'
          ? 'live'
          : playlistStore.isLinearPlaylist()
          ? 'linear'
          : 'vod',
      pageName: PlatformUtils.getDeviceTrackingPlatform() + '|' + id + '|' + title
    };

    googleAnalyticsStore.videoView({ ...params });
    this.videoView({ ...params });
  }

  initAppkit() {
    console.log('appkitAnalyticsStore - initAppkit');

    this.state.appkitConfig = configDataStore.getConstant('analytics') || {};
    this.state.analyticsEventsDisabled =
      configDataStore.getConstant('analytics_events_disabled') || [];
    this.state.isGoogleAnalyticsEnabled = configDataStore.getConstant('google_analytics_enabled');

    this.state.beaconSettings =
      configDataStore.getConstant('google_analytics_video_beacon_settings') || [];
    if (!this.state.beaconSettings || !this.state.beaconSettings.length) {
      ErrorReporter.captureMessage('GA', 'No beaconing intervals found');
    }
    this.state.currentBeaconInterval = this.state.beaconSettings[0];
    if (!this.state.analyticsEventsDisabled.includes('screenTracker')) {
      this.state.hitCounters = 0;
    }

    if (!this.state.analyticsEventsDisabled.includes('videoTracker')) {
      this.state.hitCounters = 0;
    }

    console.log('appkitAnalyticsStore - Appkit Analytics Enabled');

    if (!this.state.initialized) {
      const { config_url = defaultConfig } = this.state.appkitConfig;

      this.instance
        .load(config_url)
        .catch((error) => {
          console.log('appkitAnalyticsStore - onSetupFailed', error);
          this.setState({ appkitLoaded: false, loading: false });
        })
        .then(() => {
          console.log('appkitAnalyticsStore - initAppkitComplete');
          if (userAccountStore.state.isUserLoggedIn) {
            this.identifyUser(userAccountStore.getUserId());
          } else {
            this.instance.user.id(null);
            this.instance.user.traits({});
          }
          this.setState({ appkitLoaded: true, loading: false });
        });

      this.setState({ initialized: true });
    }
  }

  setDeeplinkSource(deeplinkSource) {
    this.setState({ deeplinkSource });
    googleAnalyticsStore.state.deeplinkSource = deeplinkSource;
  }

  incrementHitCounter() {
    this.state.hitCounters = this.state.hitCounters + 1;
    this.state.currentMaxHitCount = this.state.hitCounters || 0;

    let interval = _.find(this.state.beaconSettings, (beaconInterval) => {
      return this.state.currentMaxHitCount < beaconInterval.max_hit_count;
    });
    if (!interval) {
      // if this.state.currentMaxHitCount has surpassed the max_hit_count of the highest interval, use the highest interval
      interval = this.state.beaconSettings[this.state.beaconSettings.length - 1] =
        this.state.beaconSettings[this.state.beaconSettings.length - 1];
    }

    if (interval.min_hit_count !== this.state.currentBeaconInterval.min_hit_count) {
      // If we've passed the previous threshold, update the stored interval info
      this.state.currentBeaconInterval = interval;
    }
  }

  identifyUser(id, traits) {
    this.instance.user.identify(id, traits);
  }

  logoutUser() {
    this.instance.user.id(null);
    this.instance.user.traits({});
  }

  analyticsTrack(event, properties, options) {
    this.instance.track(event, properties, options);
  }

  analyticsIdentify(userId, traits, options) {
    this.instance.identify(userId, traits, options);
  }

  analyticsScreen(name, properties, options) {
    this.instance.screen(null, name, properties, options);
  }

  screenView(name, properties, options) {
    try {
      if (!properties) {
        properties = {};
      }

      console.log('appkitAnalyticsStore - screenView', name, properties);

      this.state.currentPageViewId = uuid();
      properties.title = properties.title
        ? properties.title
        : consumptionStore.state.currentAssetObject
        ? consumptionStore.state.currentAssetObject.title
        : name;
      name = name || properties.pageid;
      this.state.currentPageId = name;

      let screenViewHitFields = {
        brand: isServus ? 'servus' : 'redbull.com', // Brand
        language: PlatformUtils.getDeviceLanguage(), // Language with country code
        rb_language: PlatformUtils.getModifiedDeviceLanguage(), // Language
        locale: isServus ? 'at' : 'int', // Locale
        property_name: isServus ? 'servustv-app' : 'redbulltv-apps', // Property Name
        environment: 'on-site', // Environment
        asset_id: name, // Asset ID
        source: isServus ? 'servustv' : 'RBTV', // Source
        page_name: PlatformUtils.getDeviceTrackingPlatform() + '|' + name + '|' + properties.title, // Page Name "<device_platform>|<page id>|<page title/label>"
        collect_id: properties.collectionId || 'unspecified', // Collection ID
        device_platform: PlatformUtils.getDeviceTrackingPlatform(), // Device Platform
        platform_subtype:
          PlatformUtils.isVIDAA || PlatformUtils.isHBBTV
            ? `${PlatformUtils.sharedPlatform.getBrand()} ${PlatformUtils.sharedPlatform.getOS()}`
            : 'unspecified', // Platform Subtype
        page_id: name, // Page ID
        video_stream_type: videoViewTrackingStore.state.videoStreamType || 'unspecified', // Video Stream Type
        page_view_id: this.state.currentPageViewId || 'unspecified', // Page View ID
        playlist_position: properties.playlistPositionId || 'unspecified', // Playlist Position
        video_collection_referral: homeStore.state.lastViewedPage || 'unspecified', // Video Collection Referral
        deeplink: this.state.deeplinkSource || 'unspecified', // Deeplink
        player_type: 'fullscreen', // Mode of the video player
        beaconing_frequency:
          this.getCurrentBeaconInterval(videoViewTrackingStore.state.videoStreamType) ||
          'unspecified', // Current beaconing interval
        hit_counter_devices: this.state.currentMaxHitCount + 1, // Client internal hit counter
        ab_test_group_ids: '',
        ab_test_group_number: ''
      };
      screenViewHitFields = _.extend(screenViewHitFields, this.getSessionFields());

      let screenName;
      if (properties.searchTerm) {
        screenViewHitFields['dl'] = 'search/' + properties.searchTerm;
      } else if (properties.signInPromptView) {
        screenName = screenViewHitFields['action'] = 'Optional Registration Prompt';
      } else if (properties.signInPromptLater) {
        screenName = screenViewHitFields['action'] = 'Optional Registration Later';
        screenViewHitFields['category'] = 'Interaction';
        screenViewHitFields['label'] = 'When a user selects the Maybe Later button';
      } else if (properties.signInPromptSkip) {
        screenName = screenViewHitFields['action'] = 'Optional Registration Skip';
        screenViewHitFields['category'] = 'Interaction';
        screenViewHitFields['label'] = 'When a user selects the Skip button';
      }

      if (screenName && this.state.analyticsEventsDisabled.includes(screenName)) return;

      console.log(
        'appkitAnalyticsStore - Analytics: Sending screenViews',
        name,
        screenViewHitFields,
        options
      );
      this.incrementHitCounter();

      this.analyticsScreen(name, screenViewHitFields, options);
    } catch (e) {
      console.error(e, 'Appkit analyticsScreen execution failed');
    }
  }

  handleEndOfVideo() {
    googleAnalyticsStore.handleEndOfVideo('endVideo');
    this.videoView('endVideo');
  }

  handleCaptionSwitch() {
    googleAnalyticsStore.handleCaptionSwitch('subtitleSwitch');
    this.videoView('subtitleSwitch');
  }

  handleExit() {
    googleAnalyticsStore.handleExit('stopVideo');
    this.videoView('stopVideo');
  }

  getCurrentBeaconInterval(videoType) {
    return this.state.currentBeaconInterval.beacon_frequency[videoType];
  }

  getDurationSinceLastInterval() {
    return parseInt(videoViewTrackingStore.state.oneSecondTicks);
  }

  /**
   *
   * @param {string|any} paramsOrStr
   * @param {any} extraParams?
   */
  videoView(paramsOrStr, extraParams) {
    try {
      if (this.state.analyticsEventsDisabled.includes('videoTracker')) return;

      let params = {};

      // TODO: Should probably just update the id for BORB when we receive it from the API
      let linearChannelId =
        playlistStore.getCurrentPlayingChannel() && playlistStore.getCurrentPlayingChannel().id;
      if (linearChannelId === 'linear-borb') {
        linearChannelId = 'borb';
      }

      if (_.isString(paramsOrStr)) {
        // If only a string is passed, set the string value as true in parameters object
        // and merge with params object from videoViewTrackingStore
        params[paramsOrStr] = true;
        params = _.extend(params, videoViewTrackingStore.state.paramsGA);
      } else if (_.isObject(paramsOrStr)) {
        params = paramsOrStr;
      } else {
        console.warn('Parameter is neither string nor object, returning');
        return;
      }
      if (extraParams && _.isObject(extraParams)) {
        _.extend(params, extraParams);
      }

      const captionsAreAvailableAndSelected =
        captionStore.state.hasCaptions &&
        settingsStore.state.settings.shouldShowCC === true &&
        !params.captionsTurnedOffFromMenu &&
        captionStore.state.activeCaptionsLanguage &&
        !captionStore.state.activeCaptionsIsDisabled;
      const resolved_locale =
        (assetMetadataStore.state.metadata && assetMetadataStore.state.metadata.resolved_locale) ||
        (assetMetadataStore.state.metadataForAnalytics &&
          assetMetadataStore.state.metadataForAnalytics.resolved_locale);

      if (assetMetadataStore.state.metadataForAnalytics)
        assetMetadataStore.state.metadataForAnalytics = {};

      let videoViewHitFields = {
        category: 'video', // Event Category (may be redefined later in this function)
        brand: isServus ? 'servus' : 'redbull.com', // Brand
        language: PlatformUtils.getDeviceLanguage(), // Language with country code
        rb_language: PlatformUtils.getModifiedDeviceLanguage(), // Language
        locale: isServus ? 'at' : 'int', // Locale
        property_name: isServus ? 'servustv-app' : 'redbulltv-apps', // Property Name
        environment: 'on-site', // Environment
        asset_id: params.id || 'unspecified', // Asset ID
        video_account_name: isServus ? 'Servus TV App Relaunch' : 'RBTV', // BC Account Name
        video_name: params.videoTitle || 'unspecified', // Video Name
        cms_video_name: params.videoTitle || 'unspecified', // CMS Video Name
        absolute_position: isNaN(params.absolutePosition)
          ? '00000'
          : padToFive(params.absolutePosition), // Absolute Position in 10 or 60 sec increments
        video_autoplay: this.state.playType
          ? this.state.playType === 'auto'
            ? 'true'
            : 'false'
          : 'unspecified', // Video Autoplay
        video_id: params.id || 'unspecified', // Video ID
        video_player_id: PlatformUtils.getDeviceTrackingPlatform() || 'unspecified', // Player ID
        video_locale: resolved_locale || 'unspecified',
        video_stream: params.channel === 'live' || params.channel === 'linear' ? 'true' : 'false', // Video Stream  <true if live or linear, false for VOD>
        video_product_id: params.id, // Video Product ID  <video ID (AP #)>
        video_asset_id: 'unspecified', // Video Asset ID
        video_audio_language:
          PlatformUtils.convertISO2CodeToISO1(videoPlayerStore.state.currentAudioLanguageCode) ||
          'unspecified', // Audio stream language
        video_subtitle_language: captionsAreAvailableAndSelected
          ? PlatformUtils.convertISO2CodeToISO1(captionStore.state.activeCaptionsLanguage)
          : 'unspecified' || 'unspecified', // Video subtitles language (unspecified if subtitles are off)
        channel: params.channel === 'linear' ? linearChannelId : 'unspecified', // Channel TODO: Get the name of playing Channel if user navigated through a Channel page
        source: isServus ? 'servustv' : 'RBTV', // Source
        page_name: params.pageName, // Page Name <Page Name> in format "<device_platform>|<page id>|<page title/label>"
        collect_id: params.listId || 'unspecified', // Collection ID
        device_platform: PlatformUtils.getDeviceTrackingPlatform() || 'unspecified', // Device Platform
        platform_subtype:
          PlatformUtils.isVIDAA || PlatformUtils.isHBBTV
            ? `${PlatformUtils.sharedPlatform.getBrand()} ${PlatformUtils.sharedPlatform.getOS()}`
            : 'unspecified', // Platform Subtype
        page_id: consumptionStore.state.currentAssetObject
          ? consumptionStore.state.currentAssetObject.id
          : 'unspecified', // Page ID <Page ID>
        video_stream_type: params.channel || 'unspecified', // Video Stream Type
        video_play_id: this.state.videoViewId || 'unspecified', // Video Play ID <RBTV Video View ID> or (UUID4)
        page_view_id: this.state.currentPageViewId, // Page View ID  <RBTV Page View ID> (UUID4)
        playlist_position: playlistStore.state.currentlyWatchingIndex || 'unspecified', // Playlist Position <RBTV Playlist Position>
        video_collection_referral: homeStore.state.lastViewedPage || 'unspecified', // Video Collection Referral
        deeplink: this.state.deeplinkSource || 'unspecified', // Deeplink
        player_type: 'fullscreen', // Mode of the video player
        beaconing_frequency: this.getCurrentBeaconInterval(params.channel) || 'unspecified', // Current beaconing interval
        hit_counter_devices: this.state.currentMaxHitCount + 1, // Client internal hit counter
        ab_test_group_ids: 'unspecified',
        ab_test_group_number: 'unspecified',
        promo_batch_count: 'unspecified',
        promo_name: 'unspecified',
        widget_type: 'unspecified'
      };

      let event;
      if (params.languageSwitch) {
        console.log('appkitAnalyticsStore - RTM: languageSwitch!');
        event = 'languageSwitch';
        videoViewHitFields['label'] =
          PlatformUtils.convertISO2CodeToISO1(videoPlayerStore.state.currentAudioLanguageCode) ||
          'unspecified';
      } else if (params.languagePlay) {
        console.log('appkitAnalyticsStore - RTM: languagePlay!');
        event = 'languagePlay';
        videoViewHitFields['label'] =
          PlatformUtils.convertISO2CodeToISO1(videoPlayerStore.state.currentAudioLanguageCode) ||
          'unspecified';
      } else if (params.subtitleSwitch) {
        console.log('appkitAnalyticsStore - RTM: subtitleSwitch!');
        event = 'subtitleSwitch';
        videoViewHitFields['label'] =
          settingsStore.state.settings.shouldShowCC === true && !params.captionsTurnedOffFromMenu
            ? PlatformUtils.convertISO2CodeToISO1(captionStore.state.activeCaptionsLanguage)
            : 'unspecified';
      } else if (params.posBeacon) {
        console.log('RTM: posBeacon!');
        videoViewHitFields['playtime'] = this.getDurationSinceLastInterval(); // Duration since last beacon
        videoViewHitFields['video_pos'] = this.getDurationSinceLastInterval(); // Duplicate of CM2 with intent of retiring CM2 in the future
        event = 'pos';
      } else if (params.playInitial) {
        console.log('appkitAnalyticsStore - RTM: playInitial!');
        videoViewHitFields['absolute_position'] = '00000';
        videoViewHitFields['video_plays'] = 1; // Video Plays, sent when first frame of video started playing
        if (params.channel === 'vod') {
          videoViewHitFields['max_possible_playtime'] = params.mediaLength; // Video length in seconds when known, sent at player initialization (autoplay) or when user requested to play the video
        } else {
          videoViewHitFields['max_possible_playtime'] = 0;
        }
        event = 'playInitial';
        videoViewHitFields['label'] = 'start';
      } else if (params.playerLoad) {
        console.log('appkitAnalyticsStore - RTM: playerLoad!');
        videoViewHitFields['player_load'] = 1; // playerLoad, sent at player initialization (autoplay) or when user requested to play the video
        event = 'playerLoad';
      } else if (params.posRelative) {
        console.log('appkitAnalyticsStore - RTM: posRelative!');
        event = 'posRelative';
        videoViewHitFields['label'] = params.posRelativeValue + '%';
      } else if (params.playVideo) {
        console.log('appkitAnalyticsStore - RTM: playVideo!');
        event = 'play';
      } else if (params.pauseVideo) {
        console.log('appkitAnalyticsStore - RTM: pauseVideo!');
        event = 'pause';
        videoViewHitFields['video_pos'] = this.getDurationSinceLastInterval(); // CM52 partial duration beacon in seconds since
        videoViewTrackingStore.resetTicksAfterPartialDuration();
      } else if (params.seekVideo) {
        console.log('appkitAnalyticsStore - RTM: seekVideo!');
        event = 'seek';
      } else if (params.stopVideo) {
        if (!videoPlayerStore.state.isPlaying && playlistStore.isLinearPlaylist()) {
          return;
        }
        console.log('appkitAnalyticsStore - RTM: stopVideo!');
        event = 'stop';
        videoViewHitFields['video_pos'] = this.getDurationSinceLastInterval(); // CM52 partial duration beacon in seconds since
        videoViewTrackingStore.resetTicksAfterPartialDuration();
      } else if (params.endVideo) {
        console.log('appkitAnalyticsStore - RTM: endVideo!');
        event = 'eof';
        videoViewHitFields['video_pos'] = this.getDurationSinceLastInterval(); // CM52 partial duration beacon in seconds since
        videoViewHitFields['video_play_id'] = this.state.previousVideoViewId || 'unspecified'; // Video Play ID <RBTV Video View ID> or (UUID4)
        videoViewTrackingStore.resetTicksAfterPartialDuration();
      } else if (params.addFavorite) {
        console.log('appkitAnalyticsStore - RTM: addFavorite!');
        videoViewHitFields['eventCategory'] = 'favorites';
        event = 'onAddToFavorites';
        videoViewHitFields['label'] =
          extraParams.favoriteItem.id +
          '|' +
          extraParams.favoriteItem.title +
          '|' +
          extraParams.toggleType;
      } else if (params.removeFavorite) {
        console.log('appkitAnalyticsStore - RTM: removeFavorite!');
        videoViewHitFields['eventCategory'] = 'favorites';
        event = 'onRemoveFromFavorites';
        videoViewHitFields['label'] =
          extraParams.favoriteItem.id +
          '|' +
          extraParams.favoriteItem.title +
          '|' +
          extraParams.toggleType;
      } else if (
        params.BackToLive ||
        params.BackToStart ||
        params['15secBackward'] ||
        params['15secForward']
      ) {
        console.log('appkitAnalyticsStore - RTM: DVR Seek');
        videoViewHitFields['label'] = params.BackToLive
          ? 'BackToLive'
          : params.BackToStart
          ? 'BackToStart'
          : params['15secBackward']
          ? '15secBackward'
          : '15secForward';
        event = 'seek';
        videoViewHitFields['video_pos'] = this.getDurationSinceLastInterval(); // CM52 partial duration beacon in seconds since
      }

      if (event && this.state.analyticsEventsDisabled.includes(event)) return;

      videoViewHitFields = _.extend(videoViewHitFields, this.getSessionFields());
      console.log('appkitAnalyticsStore - Analytics: Sending videoViews', videoViewHitFields);

      this.analyticsTrack(event, videoViewHitFields);
      this.incrementHitCounter();
    } catch (e) {
      console.error(e, 'Appkit videoView execution failed');
    }
  }

  addImpressionBatch(impressionItemsToSend, action) {
    if (this.state.analyticsEventsDisabled.includes('module_impression')) return;

    if (!impressionItemsToSend || !impressionItemsToSend.length) {
      return;
    }
    _.forEach(_.chunk(impressionItemsToSend, 12), (items) => {
      const impressionViewHitFields = {
        brand: isServus ? 'servus' : 'redbull.com', // Brand
        language: PlatformUtils.getDeviceLanguage(), // Language with country code
        rb_language: PlatformUtils.getModifiedDeviceLanguage(), // Language
        locale: isServus ? 'at' : 'int', // Locale
        property_name: isServus ? 'servustv-app' : 'redbulltv-apps', // Property Name
        site_type: 'app', // Site Type
        user_agent: navigator.userAgent, // User Agent
        client_id: appStore.state.uid, // Client ID
        logged_in_status: userAccountStore.state.isUserLoggedIn ? 'true' : 'false', // Logged In Status
        user_id: userAccountStore.state.isUserLoggedIn
          ? userAccountStore.getUserId()
          : 'unspecified', // User ID
        source: 'RBTV', // Source
        page_name: document.location.pathname, // Page Name
        device_platform: PlatformUtils.getDeviceTrackingPlatform(), // Device-Platform
        platform_subtype:
          PlatformUtils.isVIDAA || PlatformUtils.isHBBTV
            ? `${PlatformUtils.sharedPlatform.getBrand()} ${PlatformUtils.sharedPlatform.getOS()}`
            : 'unspecified', // Platform Subtype
        campaign_id: '', // Campaign ID
        page_id: consumptionStore.state.currentAssetObject
          ? consumptionStore.state.currentAssetObject.id
          : 'unspecified', // Page ID
        page_view_id: this.state.currentPageViewId, // Page View ID
        hit_counter_devices: this.state.currentMaxHitCount + 1, // Client internal hit counter
        label: 'impression',
        action: action,
        category: 'module_tracking'
      };
      _.forEach(items, (impressionItem, batchIdx) => {
        const idxToUse = batchIdx + 1;
        impressionViewHitFields['promo' + idxToUse + 'id'] = impressionItem.id; // Product impression ID
        impressionViewHitFields['promo' + idxToUse + 'nm'] = impressionItem.title; // Product impression name
        impressionViewHitFields['promo' + idxToUse + 'cr'] = impressionItem.type; // Product impression creative
        impressionViewHitFields['promo' + idxToUse + 'ps'] =
          impressionItem.row + '.' + (impressionItem.index + 1); // Product impression position
      });
      _.extend(impressionViewHitFields, this.getSessionFields());
      console.log('appkitAnalyticsStore - Analytics: Sending impressions', impressionViewHitFields);
      this.incrementHitCounter();
      this.analyticsTrack(
        action === 'view' ? 'module_impression' : 'module_click',
        impressionViewHitFields
      );
    });
  }

  optionalRegistrationPrompt(report) {
    this.screenView('Optional Registration Prompt', {
      title: 'Optional Registration Prompt',
      [report]: true
    });
  }
}

Reflux.initStore(AppkitAnalyticsStore);
const appkitAnalyticsStore = AppkitAnalyticsStore.singleton;

/**
 * These wrapper functions are to consolidate the same calls made between Appkit
 * and GA's Analytics.JS. The analytics.js will no longer be used in the near
 * future, so these functions and the GoogleAnalyticsStore can be removed
 * that that time.
 */
function analyticsVideoView(paramsOrStr, extraParams) {
  return _.invokeMap(
    [googleAnalyticsStore, appkitAnalyticsStore],
    'videoView',
    paramsOrStr,
    extraParams
  );
}

function analyticsScreenView(paramsOrStr, extraParams) {
  return _.invokeMap(
    [googleAnalyticsStore, appkitAnalyticsStore],
    'screenView',
    paramsOrStr,
    extraParams
  );
}

function analyticsAddImpressionBatch(impressionItemsToSend, action) {
  return _.invokeMap(
    [googleAnalyticsStore, appkitAnalyticsStore],
    'addImpressionBatch',
    impressionItemsToSend,
    action
  );
}

function optionalRegistrationPrompt(report) {
  return _.invokeMap(
    [googleAnalyticsStore, appkitAnalyticsStore],
    'optionalRegistrationPrompt',
    report
  );
}

export {
  appkitAnalyticsStore,
  analyticsAddImpressionBatch,
  analyticsScreenView,
  analyticsVideoView,
  optionalRegistrationPrompt
};
