/* global WebVTT: true */
import _ from 'lodash';
import { s } from '../utils/screen-size';
import Reflux from 'reflux';
import { videoTimeProxyStore } from './videoTimeProxyStore';
import { manifestStore, manifestActions } from './manifestStore';
import { consumptionStore, consumptionActions } from './consumptionStore';
import { exitStore } from './exitStore';
import api from '../services/api';
import { settingsActions, settingsStore } from './settingsStore';
import Color from 'color';
import PlatformUtils from '../utils/platform';
import isLive from '../utils/isLive';
import { videoPlayerStore } from './videoPlayerStore';
import hls from '../utils/hls_parser';

/*
   Required Fonts for CC per http://www.ecfr.gov/cgi-bin/text-idx?SID=63b331368f808a79d92ea8dc7b8f7516&mc=true&node=se47.4.79_1102&rgn=div8
   section (k):

   (i)    0 - Default (undefined)
   (ii)   1 - Monospaced with serifs (similar to Courier)
   (iii)  2 - Proportionally spaced with serifs (similar to Times New Roman)
   (iv)   3 - Monospaced without serifs (similar to Helvetica Monospaced)
   (v)    4 - Proportionally spaced without serifs (similar to Arial and Swiss)
   (vi)   5 - Casual font type (similar to Dom and Impress)
   (vii)  6 - Cursive font type (similar to Coronet and Marigold)
   (viii) 7 - Small capitals (similar to Engravers Gothic)

 */
function lookupFontFamily(font) {
  // TODO: test these on tizen device
  let table = {
    default: 'rb-regular',
    'monospace serif': 'CourierPrime',
    serif: 'Merriweather',
    'monospace sans-serif': 'UbuntuMono',
    'sans-serif': 'sans-serif',
    casual: 'Pangolin',
    cursive: 'DancingScript',
    smallcaps: 'PlayfairDisplaySC',
    korean: 'LinuxKorean',
    chinese: 'LinuxChinese'
  };
  return table[font];
}

export const captionActions = Reflux.createActions({
  loadWebVTT: { asyncResult: true },
  switchWebvttCaptions: {}
});

captionActions.loadWebVTT.listen(function (urls) {
  let promises = urls.map(api.getRaw);
  Promise.all(promises).then(this.completed).catch(this.failed);
});

export const captionStore = Reflux.createStore({
  listenables: captionActions,

  init: function () {
    this.listenTo(videoTimeProxyStore, this.trackSource);
    this.listenTo(manifestActions.setCaptionAndAudioData, this.handleNewVideoSource);
    this.listenTo(exitStore, this.cleanCaptionsObject);
    this.listenTo(settingsStore, this.settingsChanged);
    this.listenTo(consumptionActions.showVideoErrorOverlay, this.captionListOnError);
    this.setCaptionsStyles();
    this.state = {
      webvtt: null,
      activeCaptionsIndex: -1,
      activeCaptionsIsDisabled: null,
      platformCaptionsEnabled: null,
      captionLanguageOptions: [],
      audioTracks: []
    };
  },

  settingsChanged: function () {
    /* reparsing the captions stalls the app on tizen; the timeout gives
     * the UI a chance to update before we actually perform the
     * parse, which feels more responsive */
    setTimeout(() => {
      this.updateCaptions();
    }, 500);
  },

  setCaptionsStyles: function () {
    // XXX: should we be using these?
    /* var samsungCustomCaptionStyle = CaptionSamsungConfig.getStyles(); */
  },

  cleanCaptionsObject: function () {
    console.log('cleanCaptionsObject');
    this.state.currentCaption = null;
    this.state.captions = null;
    this.state.webvtt = null;
    this.state.cues = null;
    this.state.currentCaptionViews = null;
    this.state.samsungCustomCaptionStyle = null;
    this.state.activeCaptionsIndex = -1;
    this.state.activeCaptionsIsDisabled = null;
    this.trigger(this.state);
  },

  trackSource: function (state) {
    let dirty = false;
    // TODO:  Add listener if possible and just do this at startup and on change
    if (PlatformUtils.isXbox && typeof Windows !== 'undefined' && Windows.Xbox) {
      var isEnabled = Windows.Xbox.System.ClosedCaptions.ClosedCaptionProperties.isEnabled;
      if (isEnabled !== this.state.platformCaptionsEnabled) {
        /* global Windows:true */
        settingsActions.setClosedCaptions(isEnabled);
        this.state.platformCaptionsEnabled = isEnabled;
      }
    }

    // For captions is really important to have most accurate time as possible
    if (state.data.currentTime !== this.state.currentCaptionTime) {
      this.state.currentCaptionTime = state.data.currentTime;
      let currentCaptions = this.updateCurrentCaptionView(this.state.currentCaptionTime);
      if (!_.isEqual(currentCaptions, this.state.currentCaptionViews)) {
        this.state.currentCaptionViews = currentCaptions;
        dirty = true;
      }
    }

    if (dirty) {
      this.trigger(this.state);
    }
  },

  handleNewVideoSource: function () {
    if (this.state.webvtt !== null) {
      this.cleanCaptionsObject();
    } else {
      // Get captions for VOD only, not for live-channels
      if (!consumptionStore.state.isLinear) {
        console.log('content_type', consumptionStore.state.currentAssetObject.content_type);
        // bypass subtitles for live but still load the audio tracks
        const liveEvent = isLive(consumptionStore.state.data?.currentAssetObject);
        this.parseCaptionsAndAudioTracks(liveEvent);
      } else {
        if (!_.isEmpty(this.state.captionLanguageOptions)) {
          // Get rid of the captions from VOD that might be loaded
          this.state.captionLanguageOptions = [];
          this.state.hasCaptions = false;
        }
      }
    }
  },

  updateCurrentCaptionView(time) {
    let { cues } = this.state;
    let currentCues = _.filter(cues, (c) => {
      return c.startTime <= time && c.endTime >= time;
    });
    return currentCues;
  },

  getInitialData: function () {
    return this.state;
  },

  findLanguageIndex: function (languageList, languageCode) {
    const ISO639_1_APP_LANGUAGE_CODE = PlatformUtils.convertISO2CodeToISO1(
      languageCode.toLowerCase()
    );
    // Both codes should be in 2T 'eng' format
    return _.findIndex(languageList, (lang) => {
      const ISO639_1_CAPTION_LANGUAGE_CODE = PlatformUtils.convertISO2CodeToISO1(
        lang.languageCode.toLowerCase()
      );

      return (
        lang.languageCode.toLowerCase() === languageCode.toLowerCase() ||
        // check in case of mixture iso-639-2B and iso-639-2T types
        ISO639_1_CAPTION_LANGUAGE_CODE === ISO639_1_APP_LANGUAGE_CODE ||
        // check in case of mixture same languages from different countries e.g. pt_br Portuguese (Brazil) and pt Portuguese (Portugal)
        _.startsWith(ISO639_1_CAPTION_LANGUAGE_CODE, ISO639_1_APP_LANGUAGE_CODE)
      );
    });
  },

  parseCaptionsAndAudioTracks: function (bypassCaptions = false) {
    const parsedManifestData = manifestStore.state.captionAndAudioData;
    if (!bypassCaptions) {
      this.state.captionLanguageOptions = []
        .concat(parsedManifestData.subtitles)
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    this.state.hasCaptions = !!this.state.captionLanguageOptions.filter(
      (captionOpt) => !captionOpt.disabled
    ).length;
    this.state.audioTracks = []
      .concat(parsedManifestData.audioTracks)
      .sort((a, b) => a.name.localeCompare(b.name));

    if (this.state.captionLanguageOptions && this.state.captionLanguageOptions.length) {
      const forcedCaptionsIndex = _.findIndex(this.state.captionLanguageOptions, (lang) => {
        return lang.forced;
      });
      this.state.currentAssetHasForcedCaptions = forcedCaptionsIndex !== -1;
      const sessionCaptionLanguage = settingsStore.state.sessionCaptionLanguage;
      // first try the session-set language for captions
      let captionToUse = sessionCaptionLanguage
        ? this.findLanguageIndex(this.state.captionLanguageOptions, sessionCaptionLanguage)
        : -1;
      // if that is unavailable try the preferred language for captions
      if (captionToUse === -1 || this.state.captionLanguageOptions[captionToUse].disabled) {
        settingsStore.setSessionCaptionLanguage(null);
        const preferredLanguage = settingsStore.state.settings.userPreferredCaptionLanguage;
        captionToUse = this.findLanguageIndex(this.state.captionLanguageOptions, preferredLanguage);
      }
      // If neither the session language nor the preferred language is available,
      // - or if captions are turned off -,
      // check if there are forced subtitles available
      if (captionToUse === -1 || settingsStore.state.settings.shouldShowCC === false) {
        this.state.activeCaptionsIndex = -1;
        captionToUse = forcedCaptionsIndex;
      }
      // If nothing is available, don't attempt to retrieve anything
      if (captionToUse === -1) {
        this.state.activeCaptionsLanguage = null;
        this.state.activeCaptionsIndex = -1;
      } else {
        let defaultCaptions = this.state.captionLanguageOptions[captionToUse];
        this.state.activeCaptionsLanguage = defaultCaptions.forced
          ? 'OFF_OR_FORCED'
          : defaultCaptions.languageCode;
        this.state.activeCaptionsIsDisabled = defaultCaptions.disabled;
        this.state.activeCaptionsIndex = captionToUse;

        if (defaultCaptions && defaultCaptions.url) {
          if (manifestStore.state.captionAndAudioData.isDash) {
            captionActions.loadWebVTT([defaultCaptions.url]);
          } else {
            api.getRaw(defaultCaptions.url).then((playlistData) => {
              console.log('got playlistData:', playlistData);
              let subtitleURLs = hls.findSubtitles(playlistData);
              if (!subtitleURLs) return;
              let urls = subtitleURLs.map((it) => it.url);
              captionActions.loadWebVTT(urls);
            });
          }
        }
      }
    }
    if (this.state.audioTracks.length) {
      const sessionAudioLanguage = settingsStore.state.sessionAudioLanguage;
      let audioTrackToUse = sessionAudioLanguage
        ? _.findIndex(this.state.audioTracks, { languageCode: sessionAudioLanguage })
        : -1;
      if (audioTrackToUse === -1) {
        // If user's session preference is not available, use the language set in Settings
        settingsStore.setSessionAudioLanguage(null);
        audioTrackToUse = _.findIndex(this.state.audioTracks, {
          languageCode: settingsStore.state.settings.userPreferredAudioLanguage
        });
      }
      if (audioTrackToUse === -1) {
        // If neither preference is not available, use the first track
        audioTrackToUse = 0;
      }
      const selectedAudioTrack = this.state.audioTracks[audioTrackToUse];
      const selectedAudioTrackIndex =
        selectedAudioTrack.originalIndex !== undefined
          ? this.state.audioTracks[audioTrackToUse].originalIndex
          : 0;
      const selectedAudioTrackCode = selectedAudioTrack.languageCode
        ? this.state.audioTracks[audioTrackToUse].languageCode
        : 'unspecified';
      videoPlayerStore.setAudioTrack(selectedAudioTrackIndex, selectedAudioTrackCode);
    } else {
      // Single-audio VOD
      videoPlayerStore.state.currentAudioLanguageCode = null;
    }
    this.trigger(this.state);
  },

  captionListOnError: function () {
    this.state.hasCaptions = !!this.state.captionLanguageOptions.filter(
      (captionOpt) => !captionOpt.disabled
    ).length;
  },

  switchWebvttCaptions: function (selectedCode) {
    let defaultCaptions = _.find(this.state.captionLanguageOptions, (option) => {
      if (selectedCode === 'OFF_OR_FORCED') {
        return option.forced;
      } else {
        return option.languageCode === selectedCode;
      }
    });

    if (!defaultCaptions) {
      this.state.activeCaptionsLanguage = null;
      this.state.activeCaptionsIndex = -1;
      this.cleanCaptionsObject();
      return;
    }
    this.state.activeCaptionsLanguage = selectedCode;

    this.state.activeCaptionsIndex = this.findLanguageIndex(
      this.state.captionLanguageOptions,
      selectedCode
    );
    this.state.activeCaptionsIsDisabled = false;

    if (manifestStore.state.captionAndAudioData.isDash) {
      captionActions.loadWebVTT([defaultCaptions.url]);
    } else {
      api.getRaw(defaultCaptions.url).then((captionsData) => {
        let subtitleURLs = hls.findSubtitles(captionsData);
        if (!subtitleURLs) return;
        let urls = subtitleURLs.map((it) => it.url);
        captionActions.loadWebVTT(urls);
      });
    }
    this.trigger(this.state);
  },

  onLoadWebVTTCompleted(data) {
    this.state.webvtt = data.join('\n');
    this.state.cuesHaveBeenOffsetForAds = false;
    this.updateCaptions();
  },

  onLoadWebVTTFailed(error) {
    this.cleanCaptionsObject();
    this.trigger(this.state);
    console.log('Closed Caption error ' + error);
  },

  updateCaptions() {
    if (!this.state.webvtt) {
      return;
    }
    let settings = settingsStore.getCaptionValues();
    var parsed = null;
    if (this.state.webvtt) {
      parsed = this.getParsedWebvtt(this.state.webvtt, settings);
    }
    if (parsed) {
      this.state.cues = parsed;
      this.state.currentCaptionViews = this.updateCurrentCaptionView(this.state.currentCaptionTime);
      this.trigger(this.state);
    } else {
      this.state.webvtt = null;
      this.trigger(this.state);
    }
  },

  getParsedWebvtt(data, settings, displayWidth = s(1920), displayHeight = s(1080)) {
    /* this mutates the DOM elements in place */

    var parser = new WebVTT.Parser(window, WebVTT.StringDecoder()),
      cues = [],
      regions = [];
    parser.oncue = function (cue) {
      cues.push(cue);
    };
    parser.onregion = function (region) {
      regions.push(region);
    };
    parser.parse(data);
    parser.flush();
    if (cues) {
      return cues.map((cue) => ({
        startTime: cue.startTime,
        endTime: cue.endTime,
        cue: cue
      }));
    }
    return null;
  },

  webvttCueToHtml(cue, displayWidth = s(1920), displayHeight = s(1080)) {
    let settings = settingsStore.getCaptionValues();

    function flashProperty(obj, key) {
      let lookup = key + 'Opacity';
      if (obj[lookup].value === 'flashing') {
        obj[lookup].value = 1.0;
        obj[key + 'Flash'] = { value: true };
      } else {
        obj[key + 'Flash'] = { value: false };
      }
    }

    function fixupSettings(settings) {
      let result = _.cloneDeep(settings);

      const ccLanguage = captionStore.state.activeCaptionsLanguage;

      // need to translate the font setting - also need to force a font for Korean on Opera
      let platformFont = settings.textFont.value;

      switch (ccLanguage) {
        case 'kor':
          platformFont = 'korean';
          break;
        case 'zho':
          platformFont = 'chinese';
          break;
        default:
          break;
      }

      // console.log('platformFont=', platformFont, PlatformUtils.isOpera, ccLanguage);

      result.textFont.value = lookupFontFamily(platformFont);

      flashProperty(result, 'text');
      flashProperty(result, 'background');
      flashProperty(result, 'window');

      function computeShadow(style, opacity) {
        if (style === 'raised') {
          return shadowTwoColor('silver', 'black');
        }
        if (style === 'depressed') {
          return shadowTwoColor('black', 'silver');
        }
        if (style === 'uniform') {
          return shadowBorder(1, 'silver');
        }
        if (style === 'shadow') {
          let color = Color('silver').alpha(opacity).string();
          return `1px 0 0 ${color}, 1px 1px 0 ${color}, 0 1px 0 ${color}`;
        }
        throw new Error('unknown edge style');

        function shadowTwoColor(topLeftColor, bottomRightColor) {
          let topLeftRgb = Color(topLeftColor).alpha(opacity).string();
          let bottomRightRgb = Color(bottomRightColor).alpha(opacity).string();
          return `-1px 0 0 ${topLeftRgb}, -1px -1px 0 ${topLeftRgb}, 0 -1px 0 ${topLeftRgb},
              1px 0 0 ${bottomRightRgb}, 1px 1px 0 ${bottomRightRgb}, 0 1px 0 ${bottomRightRgb}`;
        }

        function shadowBorder(size, color) {
          let shadow = '';
          let rgbColor = Color(color).alpha(opacity).string();
          for (let i = -size; i <= size; i++) {
            for (let j = -size; j <= size; j++) {
              shadow += `${i}px ${j}px 0 ${rgbColor}`;
              if (j < size || i < size) {
                shadow += ',';
              }
            }
          }
          return shadow;
        }
      }

      result.textColor.value = Color(result.textColor.value)
        .alpha(result.textOpacity.value)
        .string();
      result.backgroundColor.value = Color(result.backgroundColor.value)
        .alpha(result.backgroundOpacity.value)
        .string();
      result.windowColor.value = Color(result.windowColor.value)
        .alpha(result.windowOpacity.value)
        .string();
      if (result.windowFlash.value && result.backgroundFlash.value && result.textFlash.value) {
        result.windowFlashAnimation = { value: 'blink-fgbg 1s linear infinite' };
        result.panelFlashAnimation = { value: 'blink-bg 1s linear infinite' };
      } else if (
        result.windowFlash.value &&
        result.backgroundFlash.value &&
        !result.textFlash.value
      ) {
        result.windowFlashAnimation = { value: 'blink-bg 1s linear infinite' };
        result.panelFlashAnimation = { value: 'blink-bg 1s linear infinite' };
      } else if (
        result.windowFlash.value &&
        !result.backgroundFlash.value &&
        result.textFlash.value
      ) {
        result.windowFlashAnimation = { value: 'blink-fgbg 1s linear infinite' };
      } else if (
        !result.windowFlash.value &&
        result.backgroundFlash.value &&
        result.textFlash.value
      ) {
        result.panelFlashAnimation = { value: 'blink-fgbg 1s linear infinite' };
      } else if (result.windowFlash.value) {
        result.windowFlashAnimation = { value: 'blink-bg 1s linear infinite' };
      } else if (result.backgroundFlash.value) {
        result.panelFlashAnimation = { value: 'blink-bg 1s linear infinite' };
      } else if (result.textFlash.value) {
        result.panelFlashAnimation = { value: 'blink-fg 1s linear infinite' };
      }

      if (result.textEdgeStyle.value !== 'none') {
        let alpha = Color(result.textColor.value).alpha();
        result.textEdgeStyle.value = computeShadow(result.textEdgeStyle.value, alpha);
      }
      var cellResolution = [32, 26];

      // Recover the video width and height displayed by the player.
      var videoWidth = displayWidth;
      var videoHeight = displayHeight;

      // Compute the CellResolution unit in order to process properties using sizing (fontSize, linePadding, etc).
      var cellUnit = [videoWidth / cellResolution[0], videoHeight / cellResolution[1]];
      result.textSize.value = cellUnit[1] * settings.textSize.value + 'px';

      return result;
    }

    var temporaryDiv = document.createElement('div');

    let d = temporaryDiv;
    d.style.width = displayWidth * 0.9 + 'px';
    d.style.height = displayHeight * 0.9 + 'px';
    d.setAttribute('width', displayWidth * 0.9 + 'px');
    d.setAttribute('height', displayHeight * 0.9 + 'px');
    d.style.position = 'absolute';
    d.style.top = '0px';
    d.style.left = '0px';
    d.style.visiblity = 'hidden';
    d.style.pointerEvents = 'none';
    document.body.appendChild(d);

    WebVTT.processCues(window, [cue], temporaryDiv, fixupSettings(settings));

    document.body.removeChild(d);

    while (temporaryDiv.firstChild) {
      temporaryDiv.removeChild(temporaryDiv.firstChild);
    }

    return cue.displayState.outerHTML;
  }
});
