import Reflux from 'reflux';
import _ from 'lodash';
import ConfigServiceDelegate from '../services/configServiceDelegate.js';
import SessionServiceDelegate from '../services/sessionServiceDelegate.js';
import ProductServiceDelegate from '../services/productServiceDelegate.js';
import CollectionServiceDelegate from '../services/collectionServiceDelegate.js';
import apiImageUrl, { getPreview } from '../utils/api-image-url.js';
import { imagePreload as ImagePreload } from '../services/image-preload.js';
import { epgStore } from './epgStore';
import { default as compare_versions } from '../utils/compare-versions.js';
import { configDataStore, configDataActions } from './configDataStore';
import { cookieConsentActions } from './cookieConsentStore';
import { settingsStore } from './settingsStore';
import { userAccountStore } from './userAccountStore';
import { dialogActions } from './dialogStore';
import PlatformUtils from '../utils/platform.js';
import conf from '../conf';
import { paginationStore } from './paginationStore';
import { localizationStore } from './localizationStore';
import ErrorReporter from '../utils/error-reporter';
import { appStore } from './appStore.js';

export const startActions = Reflux.createActions({
  loadInitialData: { asyncResult: true },
  loadIcons: { asyncResult: true },
  finishedLoadingProducts: {},
  finishedStartupProcess: {},
  preloadVideosByCollection: {},
  preloadSystemViewVideos: {},
  preloadSystemViewImages: {},
  markFirstPlayEventAsFinished: {}
});

/* reauthorize after 24 hours */
const reauthTimeout = 24 * 60 * 60 * 1000;

const reauthorize = () => {
  const location = document.location.href;

  const isConsumptionTopLevel =
    location.includes('Consumption') &&
    !(location.includes('contextual') || location.includes('playerControls'));

  if (isConsumptionTopLevel) {
    /* only perform the actual reload if we're at consumption to minimize edge cases */
    console.log('reloading auth and initial data');
    dialogActions.showBufferingScreen();
    startStore.state.RELOADING = true;
    startActions.loadInitialData();
    setTimeout(reauthorize, reauthTimeout);
  } else {
    /* try for consumption again in 30 seconds */
    console.log('will attempt to reauth again in 30 seconds.');
    setTimeout(reauthorize, 30 * 1000);
  }
};

setTimeout(reauthorize, reauthTimeout);

/* reauthorize when resuming from background on tizen */
if (PlatformUtils.isTizen) {
  document.addEventListener('visibilitychange', () => {
    if (document.hidden) {
      // do nothing
      console.log('visibilitychange event hidden startStore.');
    } else {
      console.log('visibilitychange event showing startStore. reauthorizing');
      reauthorize();
    }
  });
}

startActions.loadInitialData.listen(function () {
  SessionServiceDelegate.getSessionData()
    .then(startStore.onLoadSessionDataCompleted)
    .catch(startStore.onLoadInitialDataFailed);

  ConfigServiceDelegate.getConfigData().then(this.completed).catch(this.failed);
});

startActions.loadIcons.listen(function (icons) {
  apiImageUrl.preloadIcons(icons).then(this.completed).catch(this.failed);
});

export const startStore = Reflux.createStore({
  listenables: startActions,
  state: {
    data: null,
    status: null,
    isFirstPlayEventFinished: false
  },
  init: function () {
    this.preloadImage = new ImagePreload();

    this.checkAppVersion();
  },

  _handleKillSwitches: function (result) {
    let { app_update } = result;

    const isSunsettedOpera =
      PlatformUtils.isOpera && navigator.userAgent.indexOf('Swisscom-Marvell-BG2') > -1;
    const isSunsettedHbbtv =
      PlatformUtils.isHBBTV &&
      (PlatformUtils.sharedPlatform.getHbbTVVersion() === '1.1.1' ||
        PlatformUtils.sharedPlatform.getOS() === 'WEBOS2.0');
    if (isSunsettedOpera || isSunsettedHbbtv) {
      dialogActions.showDeviceNotSupportedScreen();
      this.setStartErrorObject();
      this.trigger(this.state);
      return true;
    }

    if (app_update && app_update.min_build) {
      let cmp = compare_versions(conf.version, app_update.min_build);
      // if our version is less than the min version
      let needsUpdate = cmp === -1;

      if (needsUpdate) {
        dialogActions.showappOutOfDateScreen();
        this.setStartErrorObject();
        this.trigger(this.state);
        return true;
      }
    }
  },

  onLoadInitialDataCompleted: function (result) {
    this.state.data = {};
    let killSwitchesTriggered = this._handleKillSwitches(result);
    if (!killSwitchesTriggered) {
      configDataActions.setConfigData(result);

      if (configDataStore.state.configData) {
        const localizationCallback = () => {
          if (PlatformUtils.isPS3 && conf.sunsetTime) {
            dialogActions.showSunsetMessage();
          }
        };
        this.waitforSessionData(result);
        localizationStore.setupLocalizationMappings(localizationCallback);
        this.setDefaultLanguages();
      } else {
        dialogActions.showError500Screen();
        this.setStartErrorObject();
      }
      this.trigger(this.state);
    }
    epgStore.getEPGData();
  },

  waitforSessionData: function () {
    // We need the /configuration and /session calls to return before retrieving everything else
    setTimeout(() => {
      if (this.state.sessionReady) {
        userAccountStore.checkIsUserLoggedIn();
        this.loadProducts();
        this.loadFullHeroCards();
        // Only enabled on ServusTV ATM
        if (conf.appNamespace === 'servustv') {
          cookieConsentActions.setupCookieConsent();
        }
      } else {
        this.waitforSessionData();
      }
    }, 100);
  },

  setDefaultLanguages: function () {
    // Check if captions have been enabled at the platform level by the user
    if (this.isFirstAppStartup()) {
      const appLanguageConfig = configDataStore.getConstant('languages') || [];
      const deviceLanguage = PlatformUtils.getModifiedDeviceLanguage();
      const deviceLanguageIsSupported = _.find(appLanguageConfig, (langItem) => {
        return (
          langItem['code'] === deviceLanguage ||
          langItem['iso-639-1'] === deviceLanguage ||
          langItem['iso-639-2T'] === deviceLanguage ||
          langItem['iso-639-2B'] === deviceLanguage
        );
      });
      // If the OS is set to a language that is not available in the app, default the app to English
      const preferredLanguage =
        deviceLanguageIsSupported && deviceLanguageIsSupported.code
          ? deviceLanguageIsSupported.code
          : PlatformUtils.APP_FALLBACK_LANGUAGE;
      settingsStore.state.settings.shouldShowCC =
        preferredLanguage !== (conf.appNamespace === 'servustv' ? 'ger' : 'eng') ||
        PlatformUtils.sharedPlatform.areCaptionsEnabled();
      settingsStore.setUserPreferredCaptionLanguage(preferredLanguage);
      settingsStore.setUserPreferredAudioLanguage(preferredLanguage);
    } else {
      settingsStore.state.settings.shouldShowCC = PlatformUtils.sharedPlatform.areCaptionsEnabled();
    }
  },

  preloadAssets: function () {
    this.preloadSystemViewImages();
    this.preloadClientBundleIcons();
    // this.preloadSystemViewVideos();  // Avoid preload to investigate memory issue
  },

  finishedLoadingProducts: function () {
    if (!conf.fullPreload) {
      this.state.assetsReady = true;
    } else if (!conf.delayPreload) {
      // if preload its delayed, it will be executed on startStore - onSetIsFirstPlayEvent
      this.preloadAssets();
    }

    this.loadStaticViews();
    this.finishedStartupProcess();
  },

  finishedStartupProcess: function () {
    // When everything is loaded, move past the intro screen
    if (conf.useTVSignal) {
      startActions.markFirstPlayEventAsFinished();
    }
    this.state.ready = true;
    this.state.status = 200;
    if (this.state.RELOADING) {
      dialogActions.hideBufferingScreen();
      this.state.RELOADING = false;
    }
    this.trigger(this.state);
  },

  forceDataUpdate: function (data) {
    if (this.state.RELOADING) {
      return;
    }
    this.state.data = data;
    this.state.FORCE_UPDATE = true;
    this.trigger(this.state);
    this.state.FORCE_UPDATE = false;
  },

  onLoadInitialDataFailed: function (err) {
    this.state.status = err.errorCode;
    ErrorReporter.captureException(err, 'startStore onLoadInitialDataFailed');
    dialogActions.showError500Screen();
    this.setStartErrorObject();
    if (conf.testBuild) {
      dialogActions.showEnvSwitcher();
    }
    this.trigger(this.state);
  },

  onLoadSessionDataCompleted: function (result) {
    this.state.sessionReady = true;
  },

  onLoadIconsCompleted: function (result) {
    this.state.assetsReady = true;
    this.trigger(this.state);
  },

  onLoadIconsFailed: function (err) {
    ErrorReporter.captureException(err, 'onLoadIconsFailed');
    this.state.assetsReady = true;
    this.trigger(this.state);
  },

  preloadVideosByCollection: function (collection) {
    if (PlatformUtils.supportVideoPreviews() !== true) {
      return;
    }

    if (collection.type !== 'featured') {
      return;
    }

    if (_.isEmpty(collection.items)) {
      return;
    }

    _.forEach(collection.items, (item) => {
      // skip linear and live assets
      if (item.isLinear || item.isLive) {
        return;
      }

      const { url, cached } = getPreview(item.resources, item.id);
      if (!url || cached) {
        return;
      }

      startStore.preloadImage.preloadVideo(url, item.id); // TODO: could this be related to memory leak?
    });
  },

  preloadSystemViewVideos: function () {
    let view = this.state.data.system_views?.find((view) => view.id === 'home');
    if (view === undefined) {
      return;
    }

    let collection = view.collections?.find((collection) => collection.type === 'featured');
    if (collection === undefined) {
      return;
    }

    this.preloadVideosByCollection(collection);
  },

  preloadSystemViewImages: function () {
    // Preload images for each main system view, all first level collections.
    console.log('Preload images initated');
    let views = this.state.data.system_views;
    let preloadedTypes = {};
    _.forEach(views, (view) => {
      let collectionsToPreload =
        !conf.fullPreload && view.id === 'channels'
          ? view.collections.slice(0, 2)
          : view.collections;
      _.forEach(collectionsToPreload, (collection) => {
        if (collection.items) {
          let itemsToPreload =
            !conf.fullPreload && (view.id === 'home' || view.id === 'calendar')
              ? collection.items.slice(0, 5)
              : collection.items;
          _.forEach(itemsToPreload, (item) => {
            let itemType = collection.type === 'generic' ? item.content_type : collection.type;
            if (item.resources && itemType !== 'format') {
              // Load main card images
              this.preloadImage.preloadSource(
                apiImageUrl.getImageByType(itemType, item.resources, item.id)
              );
              // Keep track of what we preload
              if (!preloadedTypes[itemType]) {
                preloadedTypes[itemType] = 0;
              }
              preloadedTypes[itemType] = preloadedTypes[itemType] + 1;
            }
          });
        }
      });
    });
    console.info('Preloaded images from /start call:', preloadedTypes);
  },

  preloadClientBundleIcons: function () {
    let notActiveOrFocused = { active: false, focused: false };
    let activeNotFocused = { active: true, focused: false };
    let activeAndFocused = { active: true, focused: true };

    let iconsToPreload = {
      'arrow-down': { states: [notActiveOrFocused, activeAndFocused] },
      'arrow-left': { states: [notActiveOrFocused, activeAndFocused] },
      'arrow-right': { states: [notActiveOrFocused, activeAndFocused] },
      'arrow-up': { states: [notActiveOrFocused, activeAndFocused, activeNotFocused] },
      back: { states: [notActiveOrFocused, activeAndFocused, activeNotFocused] },
      backspace: { states: [notActiveOrFocused, activeNotFocused] },
      backward: { states: [notActiveOrFocused, activeNotFocused] },
      captions: { states: [notActiveOrFocused, activeNotFocused] },
      check: { states: [activeNotFocused] },
      'check-black': { states: [activeNotFocused] },
      'check-white': { states: [activeNotFocused] },
      checkbox: { states: [notActiveOrFocused, activeAndFocused] },
      checkbox_true: { states: [notActiveOrFocused, activeNotFocused, activeAndFocused] },
      clips: { states: [activeNotFocused, activeAndFocused] },
      'currently-watching': { states: [notActiveOrFocused, activeNotFocused] },
      edit: { states: [notActiveOrFocused, activeAndFocused] },
      error: { states: [notActiveOrFocused, activeNotFocused] },
      events: { states: [activeNotFocused, activeAndFocused, notActiveOrFocused] },
      forward: { states: [notActiveOrFocused, activeNotFocused] },
      films: { states: [notActiveOrFocused, activeNotFocused, activeAndFocused] },
      pause: { states: [notActiveOrFocused, activeNotFocused, activeAndFocused] },
      play: { states: [notActiveOrFocused, activeNotFocused, activeAndFocused] },
      rbtv_logo_white: { states: [activeNotFocused], dims: { width: 200, height: 83 } },
      search: { states: [notActiveOrFocused, activeNotFocused] },
      shows: { states: [notActiveOrFocused, activeNotFocused, activeAndFocused] },
      space: { states: [notActiveOrFocused, activeNotFocused] },
      'star-dark-empty': { states: [notActiveOrFocused, activeNotFocused] },
      'star-dark': { states: [notActiveOrFocused, activeNotFocused] },
      radio_button_on: { states: [notActiveOrFocused, activeAndFocused] },
      radio_button_off: { states: [notActiveOrFocused, activeAndFocused] },
      'view-more': { states: [notActiveOrFocused, activeNotFocused, activeAndFocused] }
    };

    startActions.loadIcons(iconsToPreload);
  },

  setStartErrorObject: function () {
    this.state.ready = true;
    this.state.data = {};
    // They're not ready but we need to trigger this to move into Consumption, where the
    // error screen will show.
    this.state.assetsReady = true;
    this.state.sessionReady = true;
    this.state.status = 500;
    if (this.state.RELOADING) {
      dialogActions.hideBufferingScreen();
      this.state.RELOADING = false;
    }
    this.trigger(this.state);
  },

  loadProducts: function () {
    let products = Object.assign({}, configDataStore.state.configData.nav);
    const referenceCollections = configDataStore.getConstant('reference_collections') || {};
    const referenceCollectionsIds = _.keys(referenceCollections);

    var promises = [];
    this.state.data.system_views = [];
    const isServus = conf.appNamespace === 'servustv';

    // Define ordering and manually add an id value (may already be present)
    if (_.isObject(products.discover)) {
      products.discover.id = 'home';
      this.state.data.system_views.push(products.discover);
    }
    if (isServus) {
      if (_.isObject(products.calendar)) {
        products.calendar.id = 'calendar';
        this.state.data.system_views.push(products.calendar);
      }
      if (_.isObject(products.channels)) {
        products.channels.id = 'channels';
        this.state.data.system_views.push(products.channels);
      }
    } else {
      if (_.isObject(products.channels)) {
        products.channels.id = 'channels';
        this.state.data.system_views.push(products.channels);
      }
      if (_.isObject(products.calendar)) {
        products.calendar.id = 'calendar';
        this.state.data.system_views.push(products.calendar);
      }
    }

    _.forEach(products, (product) => {
      if (product.id && product.id !== 'tv') {
        promises.push(
          ProductServiceDelegate.getHydratedProductData(product.id).then(
            (product) => {
              let sysView = _.find(this.state.data.system_views, { id: product.id });
              _.extend(sysView, product);

              if (isServus) {
                const linerActionColIdx = _.findIndex(
                  product.collections,
                  (col) =>
                    referenceCollectionsIds.includes(col.id) &&
                    col.homeRailId === 'hero_cards' &&
                    col.homeRailAction === 'linear'
                );

                if (
                  product.id === 'home' &&
                  referenceCollectionsIds.length &&
                  _.isNumber(linerActionColIdx)
                ) {
                  epgStore.loadLinearChannelsCompleted(product.collections[linerActionColIdx]);
                }
              }

              if (product.id === 'channels' && product.collections) {
                _.forEach(product.collections, (collection) => {
                  paginationStore.loadRemainingCollectionPages(collection);
                });
              }
            },
            (error) => {
              console.info('[PRODUCT] Error', error);
            }
          )
        );
      }
    });

    Promise.all(promises).then(function () {
      startActions.finishedLoadingProducts();
    });
  },

  loadStaticViews: function () {
    // Add views we don't receive from API.
    var staticViews = [
      {
        id: 'account',
        icon: 'account',
        label: localizationStore._GET('account'),
        type: 'client_side_view'
      },
      {
        id: 'search',
        icon: 'search',
        label: localizationStore._GET('search'),
        type: 'client_side_view'
      }
    ];

    const isServus = conf.appNamespace === 'servustv';
    if (
      isServus &&
      epgStore.state.servusUserIsGeoBlocked !== true &&
      epgStore.state.isEmpty !== true
    ) {
      const epgView = {
        id: 'programm',
        icon: 'account',
        label: 'programm',
        type: 'client_side_view'
      };
      this.state.data.system_views.splice(this.state.data.system_views.length - 1, 0, epgView);
    }

    this.state.data.system_views = this.state.data.system_views.concat(staticViews);

    let lowFPSPath = `/images/loading_lowFPS${isServus ? '_black' : ''}.png`;
    if (window.resourcePath) {
      lowFPSPath = window.resourcePath + lowFPSPath;
    }

    this.preloadImage.preloadSource(lowFPSPath);
  },

  loadFullHeroCards: function () {
    const fullHeroCardsCollectionId = configDataStore.getConstant('full_hero_cards_collection_id');

    if (!fullHeroCardsCollectionId) {
      console.warn('No hero card collection returned from API');
      this.state.fullHeroCardsReady = true;
    } else {
      CollectionServiceDelegate.getCollectionData({ id: fullHeroCardsCollectionId })
        .then((data) => {
          this.state.data.fullHeroCards = data;
          this.state.fullHeroCardsReady = true;
        })
        .catch(() => {
          this.state.data.fullHeroCards = [];
          this.state.fullHeroCardsReady = true;
        });
    }
  },

  checkAppVersion: function () {
    const localStorage = PlatformUtils.sharedPlatform.localStorage();
    const savedVersion = localStorage.getItem('rbtv:versionNumber') || '0.0.0';

    if (compare_versions(conf.version, savedVersion) === 1) {
      // If current version is larger than stored version, clear localStorage
      // and store current version.
      const userLogin = localStorage.getItem('rbtv:userLogin');
      const remindMeToSignUp = localStorage.getItem('rbtv:remindMeToSignUp');
      const cookieConsent = localStorage.getItem('rbtv:cookieConsent');

      localStorage.clear();

      if (userLogin) {
        // Keep user logged in across version updates
        localStorage.setItem('rbtv:userLogin', userLogin);
      }

      if (remindMeToSignUp) {
        // Keep user signup preferences across version updates
        localStorage.setItem('rbtv:remindMeToSignUp', remindMeToSignUp);
      }

      if (cookieConsent) {
        // Keep user cookie consent preferences across version updates
        localStorage.setItem('rbtv:cookieConsent', cookieConsent);
      }

      localStorage.setItem('rbtv:versionNumber', conf.version);
    }
  },

  onMarkFirstPlayEventAsFinished: function () {
    if (this.state.isFirstPlayEventFinished) {
      return;
    }
    this.state.isFirstPlayEventFinished = true;
    this.trigger(this.state);

    if (conf.delayPreload) {
      this.preloadAssets();
    }
  },

  isFirstAppStartup: function () {
    let localStorage = PlatformUtils.sharedPlatform.localStorage();
    const hasBeenStartedBefore = localStorage.getItem('rbtv:isFirstAppStart');
    if (!hasBeenStartedBefore) {
      localStorage.setItem('rbtv:isFirstAppStart', true);
      return true;
    } else {
      return false;
    }
  }
});
