import Reflux from 'reflux';
import { videoPlayerActions } from './videoPlayerStore';
import { consumptionActions, consumptionStore } from './consumptionStore.js';
import { Navigation as Nav } from '../utils/reactv-navigation';
import API from '../services/api.js';
import conf from '../conf';
import { configDataStore } from '../reflux/configDataStore';
import { cookieConsentStore } from './cookieConsentStore.js';

export const adsActions = Reflux.createActions({
  triggerLinearAd: {},
  setInitialLinearAdPlayed: {},
  bindAdsStore: {},
  updateTimer: {},
  updateAdsPlaying: {},
  updateWillPlayAds: {},
  resetTimer: {},
  onAdError: {},
  onAdsManagerLoaded: {},
  onAdStarted: {},
  onAdComplete: {}
});

export const adsStore = Reflux.createStore({
  listenables: adsActions,
  state: {
    playInitialLinearAd: false,
    initialLinearAdPlayed: false,
    adTagUrl: '',
    adCountdownTimer: 0,
    adPosition: 0,
    totalAds: 0,
    adsPlaying: false,
    willPlayAds: false
  },
  init: function () {
    if (conf.enableAds) {
      console.log('[ads] Init');
      this.listenTo(videoPlayerActions.videoEnd, this.handleVideoEnd);
    }
  },
  getAdTagUrl: function (
    assetId,
    { initialLinearAd = false, isLinear = false, isLive = false } = {}
  ) {
    if (
      !assetId ||
      (!initialLinearAd &&
        (assetId === 'servus-fallback' ||
          assetId === configDataStore.getConstant('linear_stream_id')))
    ) {
      return;
    }
    this.state.willPlayAds = true;
    setTimeout(() => {
      console.log('%c [ads] timeout ads will not play', 'color:deeppink');
      this.updateWillPlayAds(false);
    }, 5000);
    // assetId = 'AA-25SK1N4D52112';   //Uncomment only for testing ensuring ads response
    console.log(
      `%c [ads] new stream:`,
      'color:deeppink',
      assetId,
      initialLinearAd,
      isLinear,
      isLive
    );
    const streaming_type = isLive ? 'live' : isLinear ? 'linear' : 'vod';
    const adTagUrl = API.getAdTagUrl(assetId, {
      optout: cookieConsentStore.state.cookieConsent[0]?.selected ? '0' : '1',
      streaming_type
    }, consumptionStore.state.currentAssetObject);
    // adTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dlinear&correlator=';
    // VAST 2.0 in.stream (Bees)
    // adTagUrl = 'https://ad13.adfarm1.adition.com/banner?sid=4582343&wpt=X&ctype=[2';
    // VAST 4.0 in.stream (Shows nothing but loads a banner image from Adition)
    // adTagUrl = 'https://ad13.adfarm1.adition.com/banner?sid=4582343&wpt=X&ctype=[7'
    // VAST 2.0 Wrapper (Bees)
    // adTagUrl = 'https://ad13.adfarm1.adition.com/banner?sid=4582343&wpt=X&ctype=[5'
    // VAST 4.0 Wrapper (Leaves)
    // adTagUrl = 'https://ad13.adfarm1.adition.com/banner?sid=4582343&wpt=X&ctype=[8';
    this.state.adTagUrl = adTagUrl;
    this.trigger(this.state);
    return adTagUrl;
  },
  handleVideoEnd: function () {
    console.log(`%c [ads] end video`, 'color:deeppink');
    if (this.adsLoader) {
      this.adsLoader.contentComplete();
    }
  },
  triggerLinearAd: function () {
    console.log(`%c [ads] triggerLinearAd`, 'color:deeppink');
    this.state.playInitialLinearAd = true;
    this.trigger(this.state);
  },
  setInitialLinearAdPlayed: function (value) {
    this.state.initialLinearAdPlayed = value;
    this.trigger(this.state);
  },
  bindAdsStore: function (videoElement, adsElement, callbacks = {}) {
    if (conf.enableAds) {
      console.log(`%c [ads] binding dom and callback`, 'color:deeppink');
      this.videoElement = videoElement;
      this.adsElement = adsElement;
      this.callbacks = callbacks;
    }
  },
  bitmovinBindStore: function (callbacks = {}) {
    if (conf.enableAds) {
      this.callbacks = callbacks;
    }
  },
  resetTimer: function () {
    clearInterval(adsStore.adIntervalTimer);
    this.state.adPosition = 0;
    this.state.totalAds = 0;
    this.state.adCountdownTimer = 0;
    this.trigger(this.state);
  },
  updateTimer: function (remainingTime) {
    if (!remainingTime) {
      clearInterval(adsStore.adIntervalTimer);
    }

    this.state.adCountdownTimer = remainingTime;
    this.trigger(this.state);
  },
  updateAdsPlaying: function (value) {
    this.state.adsPlaying = value;

    this.trigger(this.state);
  },
  updateWillPlayAds: function (value) {
    this.state.willPlayAds = value;

    this.trigger(this.state);
  },
  onAdError: function (e) {
    try {
      const error = e.getError();
      console.error('[ads] AdsManager could not be started', {
        message: error.getMessage(),
        errorCode: error.getErrorCode(),
        vastErrorCode: error.getVastErrorCode()
      });
    } catch (_) {
      console.error('[ads] AdsManager could not be started', e);
    }
    this.updateWillPlayAds(false);
    adsStore.resetTimer();
    this.callbacks.AD_ERROR();
  },
  onAdStarted: function (e) {
    console.log(`%c [ads] ad started`, 'color:deeppink');
    Nav.disable();
    const ad = e.getAd && e.getAd();
    if (ad && ad.isLinear()) {
      const info = ad.getAdPodInfo();
      this.state.adPosition = info.getAdPosition();
      this.state.totalAds = info.getTotalAds();
      this.state.adCountdownTimer = info.getMaxDuration();
      adsStore.adIntervalTimer = setInterval(() => {
        const remainingTime = adsStore.state.adsManager.getRemainingTime();
        adsStore.updateTimer(remainingTime);
      }, 200);
    }
  },
  onAdComplete: function (e) {
    console.log(
      `%c [ads] ad complete`,
      'color:deeppink',
      this.state.playInitialLinearAd,
      this.state.initialLinearAdPlayed
    );
    if (this.state.totalAds === this.state.adPosition) {
      Nav.enable();
      consumptionActions.resetHiddenControls();
      this.updateWillPlayAds(false);
      this.updateAdsPlaying(false);
    }

    const ad = e.getAd && e.getAd();
    if (ad && ad.isLinear()) {
      adsStore.resetTimer();
    }

    if (this.state.playInitialLinearAd) {
      adsActions.setInitialLinearAdPlayed(true);
    }
  },
  onAdLoaded: function (adEvent) {
    // TODO: investigate for linear preroll ads
    console.log(`%c [ads] onAdLoaded`, 'color:deeppink', adEvent);

    // var ad = adEvent.getAd();
    // if (!ad.isLinear()) {
    //   videoElement.play();
    // }
  }
});
