import React from 'react';
import extend from 'lodash/extend';
import get from 'lodash/get';
import includes from 'lodash/includes';
import classnames from 'classnames';
import { GridMenu } from '../../utils/reactv-menus';
import apiImageUrl from '../../utils/api-image-url';
import { paginationStore } from '../../reflux/paginationStore';
import mapRange from '../../utils/map-range';
import { userAccountStore } from '../../reflux/userAccountStore';
import * as Components from '../index';
import conf from '../../conf';
import { localizationStore } from '../../reflux/localizationStore';

export default class GridPanel extends GridMenu {
  constructor(p) {
    super(p);
    this.headerRef = React.createRef();
    this.itemRefs = {};
    this.stores = [userAccountStore];
    this.isServus = conf.appNamespace === 'servustv';
  }

  _checkForOffsetParams() {
    const savedOffset = parseInt(this.props.backParams.savedOffset, 10);
    if (!isNaN(savedOffset)) {
      const savedIndex = parseInt(this.props.backParams.savedIndex, 10);
      if (this.props.panelIndex === savedIndex) {
        const newIndex = savedOffset;
        const newRow = Math.floor(newIndex / this.props.numberOfColumns);
        this.setState({ index: newIndex, row: newRow });
        return true;
      }
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (!prevProps.resetList && this.props.resetList) {
      this.setState({ index: 0 });
    }

    // Need to know did we just get focused
    const isNewFocus =
      (this.props.focused && !prevProps.focused) || // We just got focused
      this.state.index !== prevState.index; // or the index changed

    if (prevProps.menuItems.length === this.state.index) {
      this.setState({ index: this.state.index - 1 });
    }

    if (prevProps.backParams.savedOffset !== this.props.backParams.savedOffset) {
      this._checkForOffsetParams();
    }

    if (isNewFocus && this.itemRefs[this.state.index]) {
      this._paginationCheck();
    }

    if (isNewFocus && this.props.setCoordinates) {
      const y = Math.floor(this.state.index / this.props.numberOfColumns);
      const x = this.state.index % this.props.numberOfColumns;
      this.props.setCoordinates(x, y);
    } else if (this.props.setCoordinates && this.state.index !== prevState.index) {
      const y = Math.floor(this.state.index / this.props.numberOfColumns);
      const x = this.state.index % this.props.numberOfColumns;
      this.props.setCoordinates(x, y);
    }

    if (this.props.onBottomRow) {
      this.props.onBottomRow(
        this.state.index < this.props.menuItems.length - this.props.numberOfColumns
      );
    }
  }

  componentDidMount() {
    super.componentDidMount();
    if (!this._checkForOffsetParams() && this.state.index !== 0) {
      this.setState({ index: 0 });
    }
  }

  setMenuInitialFocusIndex(index) {
    this.setState({ index });
  }

  _paginationCheck() {
    const { menuItems, collection } = this.props;
    if (!menuItems) {
      return;
    }

    const { limit: pageSize, total } = collection.meta;
    const pageMax = Math.ceil(total / pageSize);
    const currentPage = Math.ceil((this.state.index + 1) / pageSize);

    if (currentPage + 1 > pageMax) return;

    const nextPageBegins = currentPage * pageSize;
    const overThreshold = nextPageBegins - (this.state.index + 1) < pageSize / 2;
    const id = collection.id;

    if (!paginationStore.getPageByCollectionId(id, currentPage + 1) && overThreshold) {
      // If we're halfway to the next page and we don't already have it stored, hit the API
      paginationStore.loadCollectionPage(id, pageSize, currentPage + 1, collection);
    }
  }

  getCardRangeIndices() {
    const currentRow = Math.floor(this.state.index / this.props.numberOfColumns);
    const prevRow = currentRow > 0 ? Math.floor(currentRow - 1) : 0;
    const nextRow = currentRow > 0 ? Math.floor(currentRow + 1) : Math.floor(currentRow + 2);
    const idxOfFirstCardFromPrevRow = prevRow * this.props.numberOfColumns + 1 - 1;
    const idxOfLastCardFromRowAfterNext =
      nextRow * this.props.numberOfColumns + this.props.numberOfColumns * 2 - 1;
    // Show the previous row, the current row, and the next two rows
    return { idxOfFirstCardFromPrevRow, idxOfLastCardFromRowAfterNext };
  }

  getGridPanelCards(currentRow) {
    const rowType = 'ChannelCard';
    const type = get(this.props, 'menuItems[0].content_type', false);
    const { idxOfFirstCardFromPrevRow, idxOfLastCardFromRowAfterNext } = this.getCardRangeIndices();
    const isUserLoggedIn = userAccountStore.state.isUserLoggedIn;
    const cx = classnames('grid-panel-cardsWrapper', {
      'grid-panel-scrolled': this.props.shouldScroll === 'view-more' && currentRow > 0
    });

    return (
      <div className={cx}>
        {mapRange(
          this.props.menuItems,
          idxOfFirstCardFromPrevRow,
          idxOfLastCardFromRowAfterNext,
          (item, index) => {
            item.focused = index === this.state.index && this.props.focused;

            if (type === 'format') {
              if (this.isServus) {
                item.icon = apiImageUrl.getIcon('servus-' + item.id, true, false, 64, 64);
                item.iconFocused = apiImageUrl.getIcon('servus-' + item.id, true, true, 64, 64);
              } else {
                item.iconFocused = item.icon = apiImageUrl.getIcon(item.id, true, false, 64, 64);
              }
            }
            const ref = React.createRef();
            this.itemRefs[index] = ref;
            extend(item, {
              propRef: ref,
              key: index
            });
            item.iTrackingRow = this.props.iTrackingRow + Math.floor(index / 4);
            item.iTrackingIndex = (idxOfFirstCardFromPrevRow + index) % this.props.numberOfColumns;
            item.isFavorite = userAccountStore.isCardInUserFavorites(item.id);
            item.isUserLoggedIn = isUserLoggedIn;
            item.rowType = rowType;
            item.isPlaceholder = false;

            const Component = Components[item.component];
            return <Component {...item} />;
          }
        )}
      </div>
    );
  }

  render() {
    if (this.props.hidden) {
      return null;
    }

    const currentRow = Math.floor(this.state.index / this.props.numberOfColumns);
    const type = get(this.props, 'menuItems[0].content_type', false);
    const cx = classnames('grid-panel', type, {
      'grid-panel-scrolled':
        currentRow > 0 && includes(['show', 'page', 'film', 'about', 'event', 'stop'], type)
    });

    return (
      <div className={cx}>
        {currentRow === 0 ? (
          <h1 ref={this.headerRef} className="list__name">
            {localizationStore._GET_LABEL(this.props.collection.label)}
          </h1>
        ) : null}
        {this.getGridPanelCards(currentRow)}
      </div>
    );
  }
}
