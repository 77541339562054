import Reflux from 'reflux';
import _ from 'lodash';
import { consumptionActions, consumptionStore } from './consumptionStore';
import { videoTimeProxyActions } from './videoTimeProxyStore';
import { captionStore } from './captionStore';
import PlatformUtils from '../utils/platform.js';
import { playlistStore, playlistActions } from './playlistStore';
import api from '../services/api.js';
import { startActions, startStore } from './startStore';
import { manifestActions, manifestStore } from './manifestStore';
import { assetMetadataStore } from './assetMetadataStore';
import conf from '../conf';

var _defaultState = {
  currentSrc: '',
  currentAudioTrack: 0,
  duration: 0,
  loadingStarted: true,
  isLoading: true,
  isPlaying: false,
  isResuming: false,
  isStalled: false,
  isSeeking: false,
  started: false,
  seekTime: 0,
  ended: false,
  maxThumbnailFrame: 0,
  currentTime: 0,
  isBuffering: false,
  altVideo: '',
  currentBitRate: 0,
  isError: false,
  lastSrcError: null,
  currentAudioLanguageCode: conf.appNamespace === 'servustv' ? 'ger' : 'eng',
  PAUSED_BY_APP: false,
  videoPreviewMode: false
};

export const videoPlayerActions = Reflux.createActions({
  setPausedByApp: {},
  videoEnd: {},
  canPlay: {},
  setIsPlaying: {},
  setIsStalled: {},
  setIsPaused: {},
  setIsStopped: {},
  setIsBuffering: {},
  pauseVideo: {},
  playVideo: {},
  setDuration: {},
  setPlayerDurationInSeconds: {},
  getDuration: {},
  jumpToTime: {},
  haltVideo: {},
  setCurrentTime: {},
  setPlayerObjectTime: {},
  videoError: {},
  errorSoPlayLinear: {},
  playNewStream: {},
  forceRefreshLinear: {},
  backToStartDVRStream: {},
  restartDVRStream: {},
  setDurationDVR: {},
  playVideoPreview: {}
});

export const videoPlayerStore = Reflux.createStore({
  listenables: [videoPlayerActions],

  init: function () {
    this.listenTo(consumptionActions.setCurrentAsset, this.handleConsumptionStoreUpdate);
    this.listenTo(manifestActions.setIsDVR, videoPlayerActions.setDurationDVR);

    this.reloadCurrentVideo = _.throttle(this.reloadCurrentVideo, 5000, { leading: false }); // To prevent uncontrolled reloading of linear in case of error
  },

  state: _defaultState,

  setPausedByApp: function (value) {
    this.state.PAUSED_BY_APP = value;
    this.trigger(this.state);
  },

  videoEnd: function () {
    console.log(
      'videoPlayerStore.js - videoEnd',
      `this.state.videoPreviewMode=${this.state.videoPreviewMode}`
    );
    if (this.state.videoPreviewMode) return;

    this.state.isPlaying = false;
    this.state.ended = true;
    this.state.started = false;
    this.trigger(this.state);

    if (consumptionStore.state.isLinear) {
      // This shouldn't happen but apparently does on Opera
      this.forceRefreshLinear();
    }
  },

  reloadCurrentVideo: function () {
    if (consumptionStore.state.isLinear) {
      videoPlayerActions.forceRefreshLinear();
    } else {
      consumptionStore.reloadCurrentVideo();
      consumptionStore.hideAllControls();
    }
  },

  videoError: function (debugMsg) {
    if (this.state.videoPreviewMode) {
      this.state.isError = true;
      this.state.isPlaying = false;
      this.state.DO_PLAY = false;
      this.state.isLoading = false;

      return;
    }

    if (this.state.currentSrc !== this.state.lastSrcError) {
      this.reloadCurrentVideo();
      return;
    }

    console.log('videoPlayerStore: videoError', debugMsg);
    this.state.isError = true;

    if (!startStore.state.isFirstPlayEventFinished) {
      console.log('set is first play event (1)');
      startActions.markFirstPlayEventAsFinished();
    }
    this.state.isPlaying = false;
    this.state.DO_PLAY = false;
    this.state.isLoading = false;

    this.trigger(this.state);
    if (!consumptionStore.state.videoError) {
      consumptionActions.showVideoErrorOverlay(
        'universalPlayer - ' + this.state.currentSrc + ' - ' + debugMsg
      );
    }

    this.state.lastSrcError = this.state.currentSrc;
  },

  errorSoPlayLinear: function () {
    console.log('errorSoPlayLinear');
    window.FORCE_REFRESH = true;
    playlistActions.playLinearChannel();
  },

  canPlay: function () {
    this.state.isLoading = false;
    this.state.isStalled = false;

    if (!startStore.state.isFirstPlayEventFinished) {
      startActions.markFirstPlayEventAsFinished();
    }
  },

  setIsStalled: function (value = true) {
    this.state.isStalled = value;
  },

  setIsPlaying: function () {
    console.log('videoPlayerActions - setIsPlaying()');
    this.state.isPlaying = true;
    this.state.isStalled = false;
    this.state.ended = false;
    this.state.DO_PLAY = false;

    // call time update to handle correct update of play/pause display,
    // which listens to video time
    videoTimeProxyActions.setCurrentTime(this.state);

    // avoid playback glitch by update the store after current time is set
    this.trigger(this.state);
  },

  setIsPaused: function () {
    console.log('videoPlayerActions - setIsPaused()');
    if (this.state.isPlaying) {
      this.state.isPlaying = false;
      this.state.DO_STOP = true;
      this.trigger(this.state);
      this.state.DO_STOP = false;

      // call time update to handle correct update of play/pause display,
      // which listens to video time
      videoTimeProxyActions.setCurrentTime(this.state);
    }
  },

  setIsStopped: function () {
    console.log('videoPlayerActions - setIsStopped()');

    this.currentTime = 0;
    this.state.isPlaying = false;
    this.state.isSeeking = false;
    this.state.started = false;
    this.state.ended = false;
    this.state.DO_PLAY = false;

    try {
      this.state.currentSrc = 'clear';
      this.trigger(this.state);
    } catch (ex) {
      console.log('exception while stopping video: ', ex);
    }
  },

  setIsBuffering: function (isBuffering) {
    if (this.state.isBuffering === isBuffering) {
      return;
    }
    this.state.isBuffering = isBuffering;

    // Needed on Tizen for Bixby Jump to Time, but breaking other things:
    // if (isBuffering) this.state.isPlaying = false;
    this.state.ended = false;
    this.trigger(this.state);
    consumptionActions.showSpinner(isBuffering);
  },

  pauseVideo: function () {
    console.log(
      `videoPlayerActions - pauseVideo(); this.state.isPlaying = ${this.state.isPlaying}`
    );

    if (this.state.isPlaying) {
      this.state.DO_STOP = true;
      this.state.ended = false;
      this.state.started = true;
      this.trigger(this.state);
    }
  },

  // used to STOP a video (not pause it) before playing a new one
  haltVideo: function () {
    // //console.log("----------------------------------------")
    // //console.log('\n\n --> halting \n\n')
    // //console.log("----------------------------------------")
    this.state.HALT = true;
    this.trigger(this.state);
    this.state.HALT = false;
  },

  playVideo: function () {
    console.log(
      `videoPlayerActions - playVideo() = this.state.isPlaying = ${this.state.isPlaying}; this.state.PAUSED_BY_APP = ${this.state.PAUSED_BY_APP}`
    );
    if (this.state.PAUSED_BY_APP) {
      this.pauseVideo();
    } else if (!this.state.isPlaying) {
      this.state.DO_PLAY = true;
      this.state.ended = false;
      this.state.started = true;
      console.log('UP playVideo setIsBuffering - false');
      videoPlayerActions.setIsBuffering(false);
      this.state.isSeeking = false;
      this.trigger(this.state);
    }
  },
  setPlayerDurationInSeconds: function (duration) {
    // console.info('[DUR] -  duration arg is ', duration, " duration from Data is ",  this.state.duration);
    if (
      !consumptionStore.state.isLinear &&
      !playlistStore.isCurrentVideoDVR() &&
      _.isNumber(duration) &&
      duration > 0 &&
      duration !== Infinity
    ) {
      // console.info('[DUR] - setting duration to ', duration*1000 );
      this.state.duration = duration * 1000;
      this.trigger(this.state);
    }
  },

  setDurationDVR: function () {
    // Not really a duration, more the current live point in the stream
    if (playlistStore.isCurrentVideoDVR()) {
      // console.warn('DVR duration', videoPlayerStore.state.playerObjectTime);
      this.state.duration = videoPlayerStore.state.playerObjectTime;
      this.trigger(this.state);
    }
  },

  setDuration: function (duration, statusObject) {
    var newDuration;

    if (playlistStore.isCurrentVideoDVR()) {
      return;
    } else if (!duration) {
      if (!statusObject || !statusObject.start_time || !statusObject.end_time) {
        return; // console.error('Unable to calculate duration');
      }
      newDuration = new Date(statusObject.end_time) - new Date(statusObject.start_time);
    } else {
      newDuration = duration;
    }

    this.state.duration = newDuration;

    this.state.maxThumbnailFrame = Math.floor(duration / 10);
  },

  getDuration: function () {
    return this.state.duration;
  },

  setCurrentTime: function (time) {
    if (!this.state.ended) {
      this.state.currentTime = time;
      videoTimeProxyActions.setCurrentTime(this.state);
    }
  },

  setPlayerObjectTime: function (time) {
    this.state.playerObjectTime = time;
  },

  forceRefreshLinear() {
    console.log('videoPlayerStore.js - forceRefreshLinear');
    let currentChannel;
    setTimeout(() => {
      videoPlayerActions.setIsStopped();
      currentChannel = playlistStore.getCurrentPlayingChannel();
    }, 100);
    setTimeout(() => {
      playlistActions.playLinearChannel(currentChannel ? currentChannel.id : null);
    }, 200);

    if (this.state.videoPreviewMode) this.state.videoPreviewMode = false;
  },

  setAudioTrack: function (track, langCode) {
    console.log('Changing to audio track: ', track, langCode);
    this.state.currentAudioTrack = track;
    this.state.currentAudioLanguageCode = langCode;
    this.trigger(this.state);
  },

  /**
   * Jump to time in video.
   * @param time
   */
  jumpToTime: function (time) {
    console.log(`videoPlayerActions - jumpToTime(${time})`);
    if (
      time <= this.state.duration ||
      this.state.duration === 0 ||
      playlistStore.isCurrentVideoDVR()
    ) {
      this.state.isSeeking = true;
      this.state.seekTime = time;
      this.state.DO_STOP = false;
      this.trigger(this.state);
      this.state.isSeeking = false;
    } else {
      // console.log('Time out of length of video.');
    }
  },

  voiceCommand_jumpToTime: function (time) {
    if (playlistStore.isVOD() && time * 1000 <= this.state.duration && time >= 0) {
      videoPlayerActions.setIsBuffering(true);
      videoPlayerActions.jumpToTime(time);
      return true;
    } else {
      return false;
    }
  },

  voiceCommand_skipForward: function (time) {
    return this.voiceCommand_jumpToTime(this.state.currentTime + time);
  },

  voiceCommand_skipBackward: function (time) {
    return this.voiceCommand_jumpToTime(this.state.currentTime - time);
  },

  isWatching: function (url) {
    if (_.isString(url)) {
      return this.state.currentSrc === url;
    } else if (consumptionStore.state.currentAssetId) {
      // console.log("comparing %s to %s", url.id, this.state.currentAssetDetails.id)
      return url.id === consumptionStore.state.currentAssetId;
    } else return false;
  },

  newVideoSelected: function (resumePoint) {
    this.state.currentTime = resumePoint || 0;
    videoTimeProxyActions.setCurrentTime(this.state);
    this.trigger(this.state);
  },

  playVideoPreview: function (newContent) {
    console.log('videoPlayerStore.js - playVideoPreview', `newContent=${newContent}`);
    let productUrl = newContent.src;
    let posterUrl = newContent.poster;

    this.state.isLoading = true;
    this.state.isPlaying = false;
    this.state.isStalled = false;
    this.state.currentTime = 0;
    this.setPausedByApp(false);

    this.newVideoSelected(0);
    this.state.currentSrc = productUrl;
    this.state.currentPoster = posterUrl;
    this.state.videoPreviewMode = true;
    this.trigger(this.state);
  },

  playNewStream: async function (newContent, resumePoint = null, isLinear = null, dashAvailable) {
    if (this.state.videoPreviewMode) this.state.videoPreviewMode = false;
    this.setIsBuffering(true);
    assetMetadataStore.retrieveTimedMetadata(newContent.id, (dashAvailable) => {
      dashAvailable = PlatformUtils.doesNotSupportDASH() ? false : dashAvailable;

      let productUrl = newContent.src || api.getVideoUrl(newContent.id, dashAvailable);
      this.state.urlChanged = this.state.currentSrc !== productUrl;
      if (PlatformUtils.isHBBTV && conf.useTVSignal) {
        if (this.state.currentSrc === '' && !consumptionStore.state.somethingHasPlayed) {
          console.log('USE TV SIGNAL');
          this.setIsStopped();
          this.state.currentSrc = productUrl;
          this.setIsBuffering(false);
          return;
        } else {
          consumptionStore.state.somethingHasPlayed = true;
        }
      }

      if (!this.state.urlChanged) {
        if (isLinear) {
          this.setIsBuffering(false);
          return;
        }
        this.state.isPlaying = false;
        if (resumePoint) {
          this.state.currentTime = resumePoint;
          this.jumpToTime(resumePoint);
        }
        return;
      }

      videoPlayerActions.setIsStopped();

      this.state.isLoading = true;
      this.state.isPlaying = false;
      this.state.isStalled = false;
      this.state.currentTime = 0;
      if (resumePoint) {
        this.state.isResuming = true;
        this.state.seekTime = resumePoint;
      } else {
        this.state.isResuming = false;
      }
      setTimeout(() => {
        this.newVideoSelected(resumePoint);
        this.state.currentSrc = productUrl;
        this.trigger(this.state);
      }, 500);
    });
    if (newContent.content_type && newContent.content_type === 'live_program') {
      await manifestStore.setIfAssetIsDVR(newContent.id);
    }
  },

  handleConsumptionStoreUpdate: function (currentAssetObject) {
    // TODO: Check the synchronization between the this class and the VideoViewTrackingStore
    // In regards to the consumption store.   The delay below is a hack caused by a timing issue.

    // if there was a video error previously, tell the player
    // to force the url through (try playing it again)
    var force = window.FORCE_REFRESH;
    var prevSrc = this.state.currentSrc;

    setTimeout(() => {
      console.log('handling consumption store update.');
      if (this.state.urlChanged || force) {
        captionStore.cleanCaptionsObject();

        window.FORCE_REFRESH = false;
        if (!consumptionStore.state.isLinear) {
          if (force) {
            console.warn('** forcing video to play url **');
          }
          console.log('handling consumption store update, setting duration');

          this.setDuration(currentAssetObject.duration, currentAssetObject.status);
        } else {
          console.log('consumption store state is linear');
        }
      } else {
        console.log('no change detected in consumption store');
        console.log('this.state.currentSrc = ', this.state.currentSrc);
        console.log('prevSrc = ', prevSrc);
        videoPlayerActions.setIsBuffering(false);
        videoPlayerActions.playVideo();
      }
    }, 500);
  },

  backToStartDVRStream() {
    videoPlayerActions.setIsBuffering(true);
    videoPlayerActions.jumpToTime(0);
    setTimeout(() => {
      videoPlayerActions.playVideo();
    }, 200);
  },

  restartDVRStream() {
    videoPlayerActions.setIsStopped();
    this.state.currentSrc = 'clear';
    window.FORCE_REFRESH = true;
    this.playNewStream(consumptionStore.state.currentAssetObject);

    // HACK: Need a better way of waiting for the video to start playing, so we can get the currentTime and set as duration
    setTimeout(() => {
      videoPlayerActions.setDurationDVR();
    }, 5000);
  }
});
